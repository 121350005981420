import { Component } from "react"
import { Dialog } from "@mui/material"
import { Link } from "react-router-dom"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ errorInfo: errorInfo.componentStack })
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <Dialog className="modal error-boundary" open={true}>
          <h2>Uh oh!</h2>
          <span className="icon profanity" />
          <strong
            style={{
              textAlign: "center",
              display: "block",
              marginTop: 10,
              marginBottom: 15,
            }}
          >
            Something seems to have gone wrong.
          </strong>
          <p>
            Please{" "}
            <Link
              onClick={() => this.setState({ hasError: false })}
              to="/submit-ticket"
            >
              submit a MobileMind support ticket
            </Link>{" "}
            so we can look into this issue. To help us quickly find a
            resolution, copy the current URL and paste it in the ticket.
          </p>
        </Dialog>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
