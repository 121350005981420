import { isAfter } from "date-fns"
import "./styles/attendance-summary.scss"
import classNames from "classnames"
import Loading from "@mobilemind/common/src/components/Loading"

type AttendanceProps = {
  attendance: {
    accept_events: string
    attended: string
    decline_events: string
    no_reply_events: string
    tentative_events: string
    user_events: string
  }
  startTime: string
  showLabels: boolean
  alwaysShowAttended?: boolean
}

function AttendanceSummary(props: AttendanceProps) {
  const { attendance, startTime, showLabels, alwaysShowAttended } = props

  return (
    <ul
      id="attendance-summary"
      className={classNames("flexRow", !showLabels && "hoverable")}
    >
      {attendance && attendance.user_events ? (
        <>
          <li>
            <span className="icon invited" />
            {Number(attendance.user_events).toLocaleString()}
            <strong>Invited</strong>
          </li>

          {(isAfter(new Date(), new Date(startTime)) || alwaysShowAttended) && (
            <li>
              <span className="icon attended" />
              {Number(attendance.attended).toLocaleString()}
              <strong>Attended</strong>
            </li>
          )}

          <li>
            <span className="icon accept" />
            {Number(attendance.accept_events).toLocaleString()}
            <strong>Accept</strong>
          </li>

          <li>
            <span className="icon tentative" />
            {Number(attendance.tentative_events).toLocaleString()}
            <strong>Tentative</strong>
          </li>
          <li>
            <span className="icon decline" />
            {Number(attendance.decline_events).toLocaleString()}
            <strong>Decline</strong>
          </li>
        </>
      ) : (
        <Loading />
      )}
    </ul>
  )
}

export default AttendanceSummary
