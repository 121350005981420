import ImageUpload from "../../components/ImageUpload"
import "../marketplace/marketplace.scss"
import { Field, useFormikContext } from "formik"
// import { useAppSelector } from "store/hooks"
import { Grid, TextField } from "@mui/material"
import { generateVideoEmbedURL } from "@mobilemind/common/src/functions/index"

const styles = {
  fieldLabelSet: {
    display: "flex",
    flexDirection: "column" as "column",
    fontSize: 14,
    flex: 1,
  },
  helperText: {
    display: "block",
    fontSize: 12,
    opacity: 0.6,
  },
}

export const PartnerMediaForm = () => {
  const { values, setFieldValue } = useFormikContext()

  // eslint-disable-next-line
  // @ts-ignore
  const { partner_image, partner_preview, partner_promo_video } = values
  const videoEmbed =
    partner_promo_video && generateVideoEmbedURL(partner_promo_video)

  return (
    <>
      <div
        className="partner-image"
        style={{ display: "flex", marginBottom: 20 }}
      >
        <div style={{ marginRight: 10 }}>
          <ImageUpload
            useFormik={true}
            removeImage={() => {
              setFieldValue("partner_image", "")
            }}
            setImage={(image: string) => {
              setFieldValue("partner_image", image)
            }}
            activeImage={
              partner_image.length
                ? process.env.REACT_APP_API_URL + partner_image
                : null
            }
          />
        </div>
        <div style={styles.fieldLabelSet}>
          <strong>Marketplace Image</strong>
          <span style={styles.helperText}>
            Header image or logo displayed on your marketplace listing.
            <br />
            An image at least 640px wide and 250px high is recommended.
          </span>
        </div>
      </div>
      <div style={{ marginBottom: 20 }}>
        <Field
          style={{ width: "100%", marginBottom: 5 }}
          as={TextField}
          name="partner_promo_video"
          label="Promo Video"
        />
        <span style={styles.helperText}>
          YouTube, Vimeo, and Google Drive videos are currently supported.{" "}
        </span>
        {videoEmbed && (
          <div style={{ marginTop: 10 }}>
            <iframe
              title="Course Video"
              id="ytplayer"
              width="100%"
              height="362px"
              src={videoEmbed}
              style={{ borderRadius: 12, borderWidth: 0 }}
            ></iframe>
          </div>
        )}
      </div>

      <div style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <div style={styles.fieldLabelSet}>
            <strong>Preview Images</strong>
            <span style={styles.helperText}>
              One or more images previewing the kind of content organizations
              can expect.
            </span>
          </div>
        </div>

        <div className="image-grid">
          <Grid container spacing={3}>
            {partner_preview.map(
              (imageSrc: { uri: string; uuid?: string }, index: number) => {
                const activeImage = imageSrc.uri.includes("data:image")
                  ? imageSrc.uri
                  : process.env.REACT_APP_API_URL &&
                    process.env.REACT_APP_API_URL + imageSrc.uri

                return (
                  <Grid item sm={6} md={4} lg={4}>
                    <ImageUpload
                      useFormik={true}
                      activeImage={activeImage}
                      removeImage={() => {
                        const edited = [...partner_preview]
                        edited.splice(index, 1)
                        setFieldValue("partner_preview", edited)
                      }}
                      setImage={(image: string) => {
                        const edited = [...partner_preview]
                        edited.splice(index, 1, { uri: image })
                        setFieldValue("partner_preview", edited)
                      }}
                    />
                  </Grid>
                )
              }
            )}

            <Grid item sm={6} md={4} lg={4}>
              <ImageUpload
                useFormik={true}
                clearOnUpload={true}
                setImage={(image: string) => {
                  setFieldValue(
                    "partner_preview",
                    partner_preview.concat([{ uri: image }])
                  )
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}
