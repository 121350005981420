import { useState } from "react"
import { BarChart } from "@mui/x-charts/BarChart"
import { axisClasses } from "@mui/x-charts/ChartsAxis"
import FeedbackColor from "../../../img/feedback-form-color.svg"
import { Card, Dialog } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonSmall from "components/ButtonSmall"
import pluralize from "pluralize"
import UserInitial from "@mobilemind/common/src/components/UserInitial"

function OpenEndedQuestionList({ question, isPreview }) {
  return (
    <ul
      style={{
        listStyleType: "none",
      }}
    >
      {question.open_question_data &&
        question.open_question_data
          .filter((open_question) => open_question.response)
          .map((open_question, index) => {
            if (open_question) {
              if ((isPreview && index <= 4) || !isPreview) {
                return (
                  <li
                    style={{
                      borderTop: !index ? "1px solid #f0f0f0" : "none",
                      ...styles.responseListItem,
                    }}
                  >
                    <p style={{ margin: 0, fontSize: 14 }}>
                      <em>"{open_question.response}"</em>
                    </p>
                    {open_question.first_name && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        {!open_question.user_picture ||
                        open_question.user_picture.includes("default_temp") ? (
                          <UserInitial
                            containerStyle={{ marginRight: 10 }}
                            size={30}
                            fontSize={12}
                            initials={
                              open_question.first_name.toUpperCase().charAt(0) +
                              open_question.last_name.toUpperCase().charAt(0)
                            }
                          />
                        ) : (
                          <span
                            className="userPicture icon"
                            style={{
                              width: 30,
                              height: 30,
                              marginRight: 10,
                              boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.2)",
                              border: "3px solid white",

                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_API_URL +
                                open_question.user_picture +
                                ")",
                            }}
                          />
                        )}
                        <strong style={{ fontSize: 14 }}>
                          {open_question.first_name} {open_question.last_name}
                        </strong>
                      </div>
                    )}
                  </li>
                )
              }
            }
            return null
          })}
    </ul>
  )
}
function EventFeedbackSummary(props) {
  const eventRef = props.isConference
    ? props.activeConference
    : props.activeEvent
  const { feedbackForm } = eventRef
  const summaryObject = eventRef.feedbackSummary?.data
  const questionData = summaryObject?.question_data ?? []

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogQuestion, setDialogQuestion] = useState(null)

  return (
    <div className="feedback-summary">
      <Dialog
        className="modal feedback-question-modal"
        open={dialogQuestion && dialogOpen ? true : false}
        onClose={() => {
          setDialogOpen(false)
        }}
      >
        {dialogQuestion && (
          <div className="modal-content">
            <h2>{dialogQuestion.question_text}</h2>
            <OpenEndedQuestionList question={dialogQuestion} />
          </div>
        )}
      </Dialog>

      <h2 style={{ display: "flex" }}>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
            className="feedback-form-popover"
          >
            <img
              src={FeedbackColor}
              alt="Feedback"
              style={{ width: 25, height: 25, marginRight: 10 }}
            />
            <div className="popover-inner">
              {feedbackForm && feedbackForm.attributes.label}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="count">
              {" "}
              {summaryObject.total_form_responses_count}
            </span>{" "}
            <span style={{ fontSize: 14 }}>
              {pluralize("Response", summaryObject.total_form_responses_count)}
            </span>
          </div>
        </div>
      </h2>

      {feedbackForm &&
        questionData.map((question, index) => {
          const likertData = question.likert_data
          const { field_scale_min, field_scale_max } = feedbackForm.attributes

          const barData = []

          if (likertData) {
            let numberArray

            if (field_scale_max && (field_scale_min || field_scale_min === 0)) {
              numberArray = Array.from(
                {
                  length: Math.abs(field_scale_max - field_scale_min + 1),
                },
                (_, i) => {
                  const modifier = Number(field_scale_min) ?? 0
                  return i + modifier
                }
              )
            }

            numberArray.forEach((score) => {
              const activeItem = likertData[0][score]
              barData.push(activeItem ? activeItem.percent : 0)
            })

            const chartSetting = {
              sx: {
                [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                  transform: "translateX(-10px)",
                },
              },
            }

            const valueFormatter = (value) => `${value}%`

            const { likert_min_label, likert_max_label } = likertData

            return (
              <Card sx={{ padding: 4, marginTop: 7 }}>
                <header
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    marginBottom: -40,
                  }}
                >
                  <div style={styles.questionName}>
                    {index + 1}. {question.question_text}
                    <div style={styles.responsesHeader}>
                      {question.question_response_count}{" "}
                      {pluralize("Response", question.question_response_count)}
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <div style={styles.avgContainerOuter}>
                      Average Rating
                      <div style={styles.avgContainer}>{question.average}</div>
                    </div>
                  </div>
                </header>
                <BarChart
                  {...chartSetting}
                  height={250}
                  slotProps={{
                    bar: {
                      clipPath: `inset(0px round 10px 10px 0px 0px)`,
                    },
                  }}
                  colors={[theme.palette.secondary.main]}
                  tooltip={{ trigger: "item" }}
                  series={[{ data: barData, valueFormatter }]}
                  yAxis={[
                    {
                      min: 0,
                      max: 100,
                      label: "% Responding",
                      labelStyle: { opacity: 0.6 },
                    },
                  ]}
                  xAxis={[
                    {
                      label: "Rating",
                      labelStyle: { opacity: 0.6 },
                      data: numberArray,
                      scaleType: "band",
                      tickLabelInterval: (value) => Number.isInteger(value),
                    },
                  ]}
                />
                <div style={styles.minMaxLabels}>
                  {likert_min_label ? (
                    <span style={styles.minMaxLabels.span}>
                      {likert_min_label}
                      <div
                        style={{
                          ...styles.minMaxTriangle,
                          left: 44,
                        }}
                      />
                    </span>
                  ) : (
                    <div />
                  )}

                  {likert_max_label && (
                    <span style={styles.minMaxLabels.span}>
                      {likert_max_label}
                      <div
                        style={{
                          ...styles.minMaxTriangle,
                          right: 44,
                        }}
                      />
                    </span>
                  )}
                </div>
              </Card>
            )
          } else {
            return (
              <Card sx={{ padding: 4, marginTop: 7 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={styles.questionName}>
                    {index + 1}. {question.question_text}
                    <div style={styles.responsesHeader}>
                      {question.question_response_count}{" "}
                      {pluralize("Response", question.question_response_count)}
                    </div>
                  </div>
                  <ButtonSmall
                    onClick={() => {
                      setDialogQuestion(question)
                      setDialogOpen(true)
                    }}
                  >
                    View All
                  </ButtonSmall>
                </div>

                <OpenEndedQuestionList question={question} isPreview={true} />
              </Card>
            )
          }
        })}
    </div>
  )
}

export default EventFeedbackSummary

const styles = {
  responseListItem: {
    padding: 15,
    paddingLeft: 25,
    marginLeft: -20,
    marginRight: -20,
    borderBottom: "1px solid #f0f0f0",
  },
  minMaxLabel: {
    padding: "5px 10px",
    color: "white",
    borderRadius: 12,
    fontSize: 12,
    backgroundColor: "black",
    position: "relative",
  },
  responsesHeader: {
    textTransform: "uppercase",
    fontSize: 12,
    opacity: 0.5,
    fontWeight: "normal",
    marginBottom: 15,
    marginTop: 5,
  },
  avgContainerOuter: {
    fontSize: 12,
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
  },
  avgContainer: {
    marginLeft: 5,
    fontSize: 14,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
    width: 40,
    height: 30,
    backgroundColor: "black",
  },
  questionName: {
    flex: 1,
    marginRight: 20,
    fontSize: 18,
    paddingLeft: 10,
    fontWeight: "bold",
  },
  minMaxLabels: {
    position: "relative",
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
    color: "white",
    marginTop: -25,
    paddingBottom: 10,
    paddingLeft: 22,
    paddingRight: 22,
    span: {
      backgroundColor: "black",
      borderRadius: 8,
      display: "flex",
      fontSize: 12,
      justifyContent: "center",
      alignItems: "center",
      padding: "5px 10px",
      minWidth: 20,
    },
  },
  minMaxTriangle: {
    position: "absolute",
    top: -5,
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderBottom: "6px solid black",
  },
}
