import React, { useEffect, useState } from "react"
import { useAppDispatch } from "store/hooks"

import {
  Button,
  FormControl,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  Popover,
  Dialog,
  Stack,
  Typography,
} from "@mui/material"
import QRCode from "react-qr-code"

import { Link } from "react-router-dom"

import { isAfter } from "date-fns"
import classNames from "classnames"
import MobilePhoneIcon from "../../../img/mobile-phone.svg"
import {
  updateInviteeSearch,
  updateField,
  increaseInviteePage,
  updateCheckedInFilter,
  updateInviteeRSVPFilter,
  updateGroupFilter,
  getUserEvents,
  updateEventSortBy,
  updateEventSortOrder,
} from "./activeEventSlice"

import {
  updateConferenceInviteeRSVPFilter,
  updateConferenceCheckedInFilter,
  updateConferenceGroupFilter,
  updateConferenceSortBy,
  updateConferenceSortOrder,
  increaseConferenceInviteePage,
} from "../conference/activeConferenceSlice"

import {
  checkInAttendee,
  updateInviteeRSVP,
  updatePDCredit,
  fetchEventAttendance,
} from "../../../actions/events"

import moment from "moment"

import Loading from "@mobilemind/common/src/components/Loading"

import {
  editConferenceEvent,
  updateConferenceInviteeSearch,
  updateConferenceField,
} from "../conference/activeConferenceSlice"

import { convertEstimatedTime } from "@mobilemind/common/src/functions"

import "react-quill/dist/quill.snow.css"
import qs from "qs"
import he from "he"
import ModalJoinCode from "../../../components/ModalJoinCode"

import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { setDSTDisplay } from "@mobilemind/common/src/functions"

function PDCreditCell(props) {
  const { invitee, cellProps } = props
  const { canSchedule, eventRole } = cellProps

  const [anchorEl, setAnchorEl] = React.useState(null)

  let credit = invitee.field_credit
    ? convertEstimatedTime(invitee.field_credit.replace("min", ""))
    : "0h 0m"

  let hours = convertMinutesToHoursMinutes(invitee.field_credit).hours
  let minutes = convertMinutesToHoursMinutes(invitee.field_credit).minutes

  const [newHours, setNewHours] = React.useState(hours)
  const [newMinutes, setNewMinutes] = React.useState(minutes)
  const [PDValue, setPDValue] = React.useState(credit)

  const dispatch = useAppDispatch()

  useEffect(() => {
    setPDValue(
      invitee.field_credit
        ? convertEstimatedTime(invitee.field_credit.replace("min", ""))
        : "0h 0m"
    )
  }, [invitee])

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const cancel = () => {
    setAnchorEl(null)
    setNewHours(hours)
    setNewMinutes(minutes)
  }

  const updatePD = () => {
    let savedMinutes = Number(newHours) * 60 + Number(newMinutes)
    setAnchorEl(null)
    setPDValue(convertEstimatedTime(savedMinutes))
    dispatch(updatePDCredit(invitee, savedMinutes))
  }

  let canEdit =
    invitee.field_attended !== "0" && (canSchedule || eventRole === "Secretary")

  return (
    <TableCell>
      <div
        className="pdCreditCell"
        style={{ pointerEvents: canEdit ? "all" : "none" }}
      >
        <Button className="button small" onClick={handleClick}>
          {PDValue}
        </Button>

        <div className="edit" />
        <Popover
          id={"creditPopoverContainer"}
          open={anchorEl}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          style={{ marginTop: -13 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="creditPopover" style={{ padding: 15 }}>
            <div className="flexRow">
              <div className="triangle"></div>

              <FormControl variant="standard">
                <TextField
                  variant="standard"
                  type="number"
                  value={newHours}
                  style={{ width: 50, marginLeft: 5, marginRight: 5 }}
                  onChange={(event) => {
                    let newValue =
                      event.target.value < 0 ? 0 : event.target.value
                    setNewHours(newValue)
                  }}
                />
              </FormControl>

              <span className="timeLabel">Hours</span>

              <FormControl variant="standard">
                <TextField
                  variant="standard"
                  type="number"
                  value={newMinutes}
                  style={{ width: 50, marginLeft: 10, marginRight: 5 }}
                  onChange={(event) => {
                    let newValue =
                      event.target.value < 0 ? 0 : event.target.value
                    setNewMinutes(newValue)
                  }}
                />
              </FormControl>
              <span className="timeLabel">Minutes</span>
            </div>

            <footer className="flexRow">
              <Button className="button small cancel" onClick={cancel}>
                Cancel
              </Button>
              <Button className="button small" onClick={updatePD}>
                Save
              </Button>
            </footer>
          </div>
        </Popover>
      </div>
    </TableCell>
  )
}

function EventParticipants(props) {
  const { editUrl, activeEvent, canSchedule, activeConference } = props
  const dispatch = useAppDispatch()

  const eventReference = props.isConference ? activeConference : activeEvent
  const [exportAnchorEl, setExportAnchorEl] = useState(null)
  const [attendance, setAttendance] = useState(null)
  const [attendanceFetched, setAttendanceFetched] = useState(false)
  const [QRCodeOpen, openQRCode] = useState(false)
  const [isCopyingLink, setIsCopyingLink] = useState(false)

  useEffect(() => {
    if (!attendanceFetched) {
      dispatch(fetchEventAttendance(eventReference.drupal_internal__id)).then(
        (response) => {
          if (response) {
            setAttendance(response.attendance_data)
            setAttendanceFetched(true)
          }
        }
      )
    }
  }, [attendanceFetched, dispatch, eventReference.drupal_internal__id])
  const [blockedUser, setBlockedUser] = useState(null)

  function updateInfo(value) {
    props.isConference
      ? dispatch(updateConferenceInviteeSearch(value))
      : dispatch(updateInviteeSearch(value))
  }

  function filterRSVP(value) {
    props.isConference
      ? dispatch(updateConferenceInviteeRSVPFilter(value))
      : dispatch(updateInviteeRSVPFilter(value))
  }

  function filterCheckedIn(value) {
    props.isConference
      ? dispatch(updateConferenceCheckedInFilter(value))
      : dispatch(updateCheckedInFilter(value))
  }

  function filterGroup(value) {
    props.isConference
      ? dispatch(updateConferenceGroupFilter(value))
      : dispatch(updateGroupFilter(value))
  }

  function toggleJoinCodeModal(value) {
    props.isConference
      ? dispatch(updateConferenceField({ field: "isJoinCodeModalOpen", value }))
      : dispatch(updateField({ field: "isJoinCodeModalOpen", value }))
  }

  function editEvent(tab) {
    if (props.isSession) {
      dispatch(editConferenceEvent(props.activeEvent))
    }
    dispatch(updateField({ field: "currentTab", value: tab }))
  }

  let checkInStart = activeEvent.checkInStart
  let checkInEnd = activeEvent.checkInEnd

  if (!moment().isDST() && moment(activeEvent.checkInStart).isDST()) {
    checkInStart = moment(activeEvent.checkInStart).add(1, "hour").format()
    checkInEnd = moment(activeEvent.checkInEnd).add(1, "hour").format()
  }

  function exportReport({ isForSessions }) {
    let windowParams =
      "left=200,top=200,width=767,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    let baseUrl =
      process.env.REACT_APP_API_URL +
      "/api/user-events-report/" +
      eventReference.drupal_internal__id +
      "/" +
      props.session.group.id[0].value

    let query = {
      search: eventReference.inviteeSearch,
    }
    if (eventReference.filterRSVP !== "any") {
      query.field_rsvp_value = eventReference.filterRSVP
    }
    if (eventReference.filterCheckedIn !== "any") {
      query.field_attended_value = eventReference.filterCheckedIn
    }
    if (eventReference.filterGroup !== "all") {
      const activeGroup = props.session.subGroups.data.find(
        (group) => group.entity_id === eventReference.filterGroup
      )
      query.field_subgroup_value = activeGroup.attributes.label
    }

    let url = baseUrl + "?" + qs.stringify(query)

    if (isForSessions) {
      url =
        process.env.REACT_APP_API_URL +
        "/api/participants-all-conference-sessions-export/" +
        eventReference.drupal_internal__id
    }

    window.open(url, "", windowParams)
  }

  function copyInviteLink() {
    let path = "/events/event/" + eventReference.drupal_internal__id

    if (props.isConference) {
      path = "/events/conference/" + eventReference.drupal_internal__id
    }
    if (props.isSession) {
      path =
        "/events/event/" +
        eventReference.drupal_internal__id +
        "/" +
        props.activeConference.drupal_internal__id
    }

    navigator.clipboard.writeText(process.env.REACT_APP_TEACHER_URL + path)

    setIsCopyingLink(true)
    setTimeout(() => {
      setIsCopyingLink(false)
    }, 4000)
  }

  let QRCodeURL = "com.mobilemind://event/" + eventReference.drupal_internal__id

  if (props.isConference) {
    QRCodeURL =
      "com.mobilemind://conference/" + eventReference.drupal_internal__id
  }

  const conferenceId =
    activeConference.drupal_internal__id ?? activeEvent.parentConferenceId

  if (props.isSession && activeConference) {
    QRCodeURL =
      "com.mobilemind://conference/" +
      conferenceId +
      "?session=" +
      eventReference.drupal_internal__id
  }

  return (
    <div className="event-attendees">
      <div className="exportContainer">
        {props.isConference ? (
          <>
            <Button
              onClick={(event) => setExportAnchorEl(event.currentTarget)}
              className="button small"
              aria-owns={exportAnchorEl ? "export-menu" : undefined}
            >
              <span className="icon export blue" />
              Export Participants
            </Button>

            <Menu
              id="export-menu"
              anchorEl={exportAnchorEl}
              open={Boolean(exportAnchorEl)}
              onClose={() => setExportAnchorEl(null)}
            >
              <MenuItem onClick={() => exportReport({ isForSessions: false })}>
                Conference
              </MenuItem>
              <MenuItem onClick={() => exportReport({ isForSessions: true })}>
                All Sessions
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Button
            style={{
              height: 35,
              position: "relative",
              top: 8,
              marginLeft: 10,
            }}
            onClick={() => exportReport({ isForSessions: false })}
            className="button small"
          >
            <span className="icon export blue" />
            Export Participants
          </Button>
        )}
      </div>
      <ModalJoinCode
        open={eventReference.isJoinCodeModalOpen}
        joinCode={eventReference.joinCode}
        onClose={() => toggleJoinCodeModal(false)}
      />
      <Dialog
        className="modal qr-modal"
        onClose={() => openQRCode(false)}
        open={QRCodeOpen}
      >
        <Stack sx={{ padding: 10, maxWidth: 500 }}>
          <QRCode
            size={500}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={QRCodeURL}
          />
          <Box
            sx={(theme) => ({
              display: "inline-flex",
              borderRadius: 4,
              marginTop: 4,
              marginBottom: -5,
              justifyContent: "center",
              backgroundColor: theme.palette.secondary.main,
              padding: 3,
            })}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
              {eventReference.joinCode}
            </Typography>
          </Box>
        </Stack>
        <Typography
          sx={{
            backgroundColor: "#ebebeb",
            display: "flex",
            alignItems: "flex-start",
            padding: 4,
            fontSize: 16,
            fontWeight: "bold",
            borderRadius: 1,
            lineHeight: "18px",
          }}
        >
          <img
            src={MobilePhoneIcon}
            alt="Mobile Phone"
            width={40}
            style={{ marginRight: 5 }}
          />
          Make sure you have downloaded and installed the MobileMind app on your
          device before scanning this code.
          <br />
          <br />
          QR codes my not work on Pixel phones. If they do not not work with the
          camera, try with a QR code reader app.
        </Typography>
      </Dialog>
      <div
        className={classNames(
          "flexRow page-widgets",
          eventReference.attendanceMethod === "manual" && "manual"
        )}
      >
        {eventReference.attendanceMethod !== "manual" && (
          <div className="widget join-code-widget">
            <header>
              <span className="icon join-code" />
              Join Code
            </header>

            <div className="inner">
              <strong>{eventReference.joinCode}</strong>
            </div>

            <footer>
              <Button
                onClick={() => openQRCode(true)}
                style={{ marginLeft: 10 }}
                className="button small"
              >
                <span className="icon qr-code" />
                QR Code
              </Button>
              <Button
                onClick={() => toggleJoinCodeModal(true)}
                style={{ marginLeft: 10 }}
                className="button small"
              >
                <span className="icon preview blue" />
                Expand
              </Button>
            </footer>
          </div>
        )}

        {!props.isObservation && (
          <div
            className={classNames(
              "widget uninvited-widget",
              eventReference.attendanceMethod === "manual" && "manual"
            )}
          >
            <header>
              <span className="icon plus-one" />
              Send Invitation
            </header>

            <div className="inner">
              <p>
                You can invite additional people by sending them this link.{" "}
              </p>
            </div>

            <footer style={{ justifyContent: "flex-end" }}>
              {isCopyingLink && (
                <div className={"linkCopied"}>Link copied!</div>
              )}
              <Button
                value={process.env.REACT_APP_TEACHER_URL + "leav"}
                onClick={copyInviteLink}
                className="button small"
              >
                <span className="icon link" />
                Copy Link
              </Button>
            </footer>
          </div>
        )}
        {eventReference.attendanceMethod !== "manual" && (
          <div className="widget check-in-widget">
            <header>
              <span className="icon check-in" />
              Check In Window
            </header>

            <div className="inner">
              <p>
                Check in starts{" "}
                <strong>{moment(checkInStart).format("MMMM Do")}</strong> at{" "}
                <strong>
                  {moment(setDSTDisplay(checkInStart)).format("h:mma")}
                </strong>{" "}
                and closes
                {moment(checkInStart).format("MM/DD") !==
                moment(checkInEnd).format("MM/DD") ? (
                  <>
                    {" "}
                    on <strong>
                      {moment(checkInEnd).format("MMMM Do")}
                    </strong>{" "}
                    at{" "}
                    <strong>
                      {moment(setDSTDisplay(checkInEnd)).format("h:mma")}
                    </strong>
                  </>
                ) : (
                  <>
                    {" "}
                    at{" "}
                    <strong>
                      {moment(setDSTDisplay(checkInEnd)).format("h:mma")}
                    </strong>
                  </>
                )}
                .
              </p>
            </div>

            {canSchedule && (
              <footer>
                <Link to={editUrl}>
                  <Button
                    onClick={() => {
                      editEvent("registration", activeEvent)
                    }}
                    className="button small"
                  >
                    <span className="icon edit blue" />
                    Edit Registration
                  </Button>
                </Link>
              </footer>
            )}
          </div>
        )}
      </div>

      <div className="attendance-container">
        <ul id="attendance-summary" className={classNames("flexRow")}>
          {attendance && attendance.user_events ? (
            <>
              <li>
                <Button
                  className={
                    eventReference.filterRSVP === "any" &&
                    eventReference.filterCheckedIn !== "1" &&
                    "active"
                  }
                  onClick={() => {
                    filterCheckedIn("any")
                    filterRSVP("any")
                  }}
                >
                  <span className="icon invited" />
                  {Number(attendance.user_events).toLocaleString()}
                  <strong>Invited</strong>
                </Button>
              </li>

              {isAfter(new Date(), new Date(eventReference.startDate)) && (
                <li>
                  <Button
                    className={
                      eventReference.filterCheckedIn === "1" &&
                      eventReference.filterRSVP === "any" &&
                      "active"
                    }
                    onClick={() => {
                      filterCheckedIn("1")
                      filterRSVP("any")
                    }}
                  >
                    <span className="icon attended" />
                    {Number(attendance.attended).toLocaleString()}
                    <strong>Attended</strong>
                  </Button>
                </li>
              )}
              <li>
                <Button
                  className={
                    eventReference.filterRSVP === "accept" &&
                    eventReference.filterCheckedIn === "any" &&
                    "active"
                  }
                  onClick={() => {
                    filterCheckedIn("any")
                    filterRSVP("accept")
                  }}
                >
                  <span className="icon accept" />
                  {Number(attendance.accept_events).toLocaleString()}
                  <strong>Accept</strong>
                </Button>
              </li>
              <li>
                <Button
                  className={
                    eventReference.filterRSVP === "tentative" &&
                    eventReference.filterCheckedIn === "any" &&
                    "active"
                  }
                  onClick={() => {
                    filterCheckedIn("any")
                    filterRSVP("tentative")
                  }}
                >
                  <span className="icon tentative" />
                  {Number(attendance.tentative_events).toLocaleString()}
                  <strong>Tentative</strong>
                </Button>
              </li>
              <li>
                <Button
                  className={
                    eventReference.filterRSVP === "decline" &&
                    eventReference.filterCheckedIn === "any" &&
                    "active"
                  }
                  onClick={() => {
                    filterCheckedIn("any")
                    filterRSVP("decline")
                  }}
                >
                  <span className="icon decline" />
                  {Number(attendance.decline_events).toLocaleString()}
                  <strong>Decline</strong>
                </Button>
              </li>
            </>
          ) : (
            <Loading />
          )}
        </ul>

        {eventReference.eventMax > 0 && attendance && (
          <div className="capsuleWrapper">
            <div className="capsule">
              <span className="icon chair" />
              {eventReference.eventMax -
                Number(attendance.accept_events)} / {eventReference.eventMax}{" "}
              seats available
            </div>
          </div>
        )}
      </div>
      <div className="participant-filters">
        <FormControl variant="standard" style={{ width: "100%" }}>
          <TextField
            style={{ width: "100%" }}
            variant="standard"
            label="Search participants"
            value={eventReference.inviteeSearch}
            onChange={(event) => updateInfo(event.target.value)}
          />
        </FormControl>

        <FormControl variant="standard" className="inputSelect filterRSVP">
          <InputLabel id="label-filterRSVP-select">RSVP Status</InputLabel>
          <Select
            variant="standard"
            labelId="label-filterRSVP-select"
            id="filterRSVP-select"
            value={eventReference.filterRSVP}
            onChange={(event) => filterRSVP(event.target.value)}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"accept"}>Accept</MenuItem>
            <MenuItem value={"tentative"}>Tentative</MenuItem>
            <MenuItem value={"decline"}>Decline</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="standard" className="inputSelect filterCheckedIn">
          <InputLabel id="label-filterCheckedIn-select">Attended</InputLabel>
          <Select
            variant="standard"
            labelId="label-filterCheckedIn-select"
            id="filterCheckedIn-select"
            value={eventReference.filterCheckedIn}
            onChange={(event) => filterCheckedIn(event.target.value)}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"1"}>Yes</MenuItem>
            <MenuItem value={"0"}>No</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="standard" className="inputSelect filterGroup">
          <InputLabel id="label-filterGroup-select">Group</InputLabel>
          <Select
            variant="standard"
            labelId="label-filterGroup-select"
            id="filterGroup-select"
            value={eventReference.filterGroup}
            onChange={(event) => filterGroup(event.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            {props.session.subGroups.data.map((group) => {
              return (
                <MenuItem key={group.entity_id} value={group.entity_id}>
                  {group.attributes.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        {(eventReference.filterRSVP !== "any" ||
          eventReference.inviteeSearch.length > 0 ||
          eventReference.filterCheckedIn !== "any") && (
          <div style={{ marginTop: 8, flexShrink: 0 }}>
            <ButtonSmall
              onClick={() => {
                filterCheckedIn("any")
                filterRSVP("any")
                filterGroup("all")
                updateInfo("")
              }}
            >
              <span className="icon plus" />
              Clear Filters
            </ButtonSmall>
          </div>
        )}
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      if (props.isConference) {
                        dispatch(
                          updateConferenceSortOrder({
                            sortOrder:
                              eventReference.sortOrder === "DESC"
                                ? "ASC"
                                : "DESC",
                          })
                        )
                        dispatch(
                          updateConferenceSortBy({
                            sortBy: "field_first_name_value",
                          })
                        )
                      } else {
                        dispatch(
                          updateEventSortOrder({
                            sortOrder:
                              eventReference.sortOrder === "DESC"
                                ? "ASC"
                                : "DESC",
                          })
                        )
                        dispatch(
                          updateEventSortBy({
                            sortBy: "field_first_name_value",
                          })
                        )
                      }
                    }}
                  >
                    {eventReference.sortBy === "field_first_name_value" && (
                      <span
                        style={{
                          transform:
                            eventReference.sortOrder === "DESC"
                              ? "scaleY(-1)"
                              : "scaleY(1)",
                        }}
                        className="icon sort"
                      />
                    )}
                    Learner
                  </Button>
                  <div
                    style={{
                      position: "absolute",
                      marginLeft: 90,
                      marginBottom: -6,
                    }}
                  >
                    {eventReference.inviteesLoading && <Loading />}
                  </div>
                </div>
              </TableCell>
              <TableCell style={{ width: 0 }} className="alignRight">
                <Button
                  style={{ marginLeft: 65 }}
                  onClick={() => {
                    if (props.isConference) {
                      dispatch(
                        updateConferenceSortOrder({
                          sortOrder:
                            eventReference.sortOrder === "DESC"
                              ? "ASC"
                              : "DESC",
                        })
                      )
                      dispatch(
                        updateConferenceSortBy({
                          sortBy: "field_rsvp_value",
                        })
                      )
                    } else {
                      dispatch(
                        updateEventSortOrder({
                          sortOrder:
                            eventReference.sortOrder === "DESC"
                              ? "ASC"
                              : "DESC",
                        })
                      )
                      dispatch(
                        updateEventSortBy({
                          sortBy: "field_rsvp_value",
                        })
                      )
                    }
                  }}
                >
                  {eventReference.sortBy === "field_rsvp_value" && (
                    <span
                      style={{
                        transform:
                          eventReference.sortOrder === "DESC"
                            ? "scaleY(-1)"
                            : "scaleY(1)",
                      }}
                      className="icon sort"
                    />
                  )}
                  RSVP
                </Button>
              </TableCell>
              <TableCell
                style={{ display: "flex", justifyContent: "end" }}
                className="alignRight checkedIn"
              >
                <Button
                  onClick={() => {
                    if (props.isConference) {
                      dispatch(
                        updateConferenceSortOrder({
                          sortOrder:
                            eventReference.sortOrder === "DESC"
                              ? "ASC"
                              : "DESC",
                        })
                      )
                      dispatch(
                        updateConferenceSortBy({
                          sortBy: "field_attended_value",
                        })
                      )
                    } else {
                      dispatch(
                        updateEventSortOrder({
                          sortOrder:
                            eventReference.sortOrder === "DESC"
                              ? "ASC"
                              : "DESC",
                        })
                      )
                      dispatch(
                        updateEventSortBy({
                          sortBy: "field_attended_value",
                        })
                      )
                    }
                  }}
                >
                  {eventReference.sortBy === "field_attended_value" && (
                    <span
                      style={{
                        transform:
                          eventReference.sortOrder === "DESC"
                            ? "scaleY(-1)"
                            : "scaleY(1)",
                      }}
                      className="icon sort"
                    />
                  )}
                  Check In
                </Button>
              </TableCell>
              {((props.isSession &&
                props.activeConference.awardPD === "sessions") ||
                (props.isConference &&
                  props.activeConference.awardPD === "conference") ||
                (!props.isConference && !props.isSession)) && (
                <TableCell style={{ width: 100, paddingRight: 0 }}>
                  PD Credit
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {eventReference.invitees && eventReference.invitees.length > 0 && (
            <TableBody>
              {eventReference.invitees.map((invitee, index) => {
                return (
                  <TableRow key={invitee.user_event_uuid}>
                    <TableCell>
                      <div className="flexRow" style={{ paddingLeft: 5 }}>
                        <UserLabel
                          imagePath={
                            process.env.REACT_APP_API_URL + invitee.user_picture
                          }
                          firstName={he.decode(invitee.field_first_name)}
                          lastName={he.decode(invitee.field_last_name)}
                          subgroups={invitee.field_subgroup}
                        />
                      </div>
                    </TableCell>

                    <TableCell>
                      <span className="status">
                        <FormControl
                          variant="standard"
                          className="inputSelect RSVP-status"
                        >
                          <Select
                            variant="standard"
                            id="RSVP-status-select"
                            value={invitee.field_rsvp}
                            onChange={(event) => {
                              let bundle = "user_event_base"
                              if (props.isConference) {
                                bundle = "conference"
                              }
                              if (props.isSession) {
                                bundle = "conference_event"
                              }

                              dispatch(
                                updateInviteeRSVP({
                                  invitee,
                                  rsvp: event.target.value,
                                  bundle,
                                })
                              ).then(() => {
                                setAttendanceFetched(false)
                              })
                            }}
                          >
                            <MenuItem value={"None"}>
                              <span className="label">No Response</span>
                              <span className="icon rsvp none" />
                            </MenuItem>
                            <MenuItem value={"Accept"}>
                              <span className="label">Accept</span>
                              <span className="icon rsvp" />
                            </MenuItem>
                            <MenuItem value={"Tentative"}>
                              <span className="label">Tentative</span>
                              <span className="icon rsvp none" />
                            </MenuItem>
                            <MenuItem value={"Decline"}>
                              <span className="label">Decline</span>
                              <span className="icon rsvp rsvp-decline" />
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </span>
                    </TableCell>

                    <TableCell className="alignRight checkedIn">
                      {blockedUser &&
                        blockedUser.user_uuid === invitee.user_uuid && (
                          <div className="blocked-user-wrapper">
                            <div className="blocked-user-warning">
                              {invitee.field_first_name}{" "}
                              {invitee.field_last_name} has been blocked and
                              cannot be checked in.
                            </div>
                          </div>
                        )}
                      <div
                        onClick={() => {
                          dispatch(
                            checkInAttendee(invitee, props.isConference)
                          ).then((response) => {
                            if (response) {
                              dispatch(getUserEvents())
                              setAttendanceFetched(false)
                            } else {
                              setBlockedUser(invitee)
                              setTimeout(() => {
                                setBlockedUser(null)
                              }, 5000)
                            }
                          })
                        }}
                        className="flexRow switch"
                      >
                        <Switch
                          checked={
                            activeEvent.checkedInAttendees.find(
                              (attendee) => attendee.id === invitee.user_uuid
                            )
                              ? true
                              : false
                          }
                          color="secondary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </TableCell>
                    {((props.isSession &&
                      props.activeConference.awardPD === "sessions") ||
                      (props.isConference &&
                        props.activeConference.awardPD === "conference") ||
                      (!props.isConference && !props.isSession)) && (
                      <PDCreditCell cellProps={props} invitee={invitee} />
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!eventReference.invitees.length &&
        eventReference.inviteeSearch &&
        eventReference.filterRSVP !== "any" && (
          <p>
            <em>Whoops, no participants match your filter critera.</em>
          </p>
        )}
      <footer className="flexRow">
        {!eventReference.inviteesLoading &&
          eventReference.inviteesCurrentPage <
            eventReference.totalInviteePages - 1 && (
            <Button
              onClick={() => {
                if (props.isConference) {
                  dispatch(increaseConferenceInviteePage())
                } else {
                  dispatch(increaseInviteePage())
                }
              }}
              className="button primary"
            >
              Load More Participants
            </Button>
          )}
      </footer>
    </div>
  )
}

export default EventParticipants
