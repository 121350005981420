import { Component, useState } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { TextField, FormControl, Button } from "@mui/material"
import { connect } from "react-redux"

import { updateField, getRooms } from "./activeEventSlice"
import {
  updateConferenceField,
  getConferenceRooms,
  addRemoveLocation,
} from "../conference/activeConferenceSlice"
import classNames from "classnames"

import Loading from "@mobilemind/common/src/components/Loading"

import {
  getLocations,
  saveLocation,
  addNewRoom,
  updateLocation,
} from "../../../actions/events"
import { resetState } from "../../locations/locationsSlice"

import {
  setNewLocation,
  setEditingLocation,
  updateNewLocation,
  filterLocations,
  updateLocationSearch,
  updateEditingLocation,
  cancelEdit,
} from "../../locations/locationsSlice"

import { addressToString } from "@mobilemind/common/src/functions"
import LocationSearchInput from "./LocationSearchInput"
import { Box } from "@mui/material"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"

import { EventLocationPanel } from "./EventLocationPanel"

const mapStateToProps = (
  { session, locations, activeConference, rooms },
  ownProps
) => {
  return {
    isConference: ownProps.isConference,
    session,
    rooms,
    conference: activeConference,
    locations,
  }
}

const mapDispatchToProps = {
  getLocations,
  setNewLocation,
  resetState,
  saveLocation,
  updateLocation,
  addNewRoom,
  getConferenceRooms,
  getRooms,
  updateLocationSearch,
}

function EventLocation(props) {
  const { locations, conference, isConference, isSession } = props
  const event = props.activeEvent
  const { editingLocation } = locations

  const dispatch = useAppDispatch()
  const [isAdding, setIsAdding] = useState(false)
  const [URLWarning, setURLWarning] = useState(false)

  const updateInfo = isConference ? updateConferenceField : updateField

  function saveLocation() {
    setIsAdding(false)
    props.saveNewLocation(true)
    selectLocation(event.currentLocation)
  }

  function saveEditingLocation() {
    props.updateLocation(editingLocation, true)
    dispatch(cancelEdit())
  }

  function editLocation(location) {
    dispatch(setEditingLocation(location))
  }

  function validateURL() {
    let url
    try {
      url = new URL(event.meetingLink)
      if (url.protocol === "http:" || url.protocol === "https:") {
        setURLWarning(false)
      }
    } catch (_) {
      setURLWarning(true)
    }
  }

  let filteredLocations = useAppSelector(filterLocations)

  function selectLocation({ location, isRemoving }) {
    dispatch(getConferenceRooms({ id: location.id }))
    if (isSession) {
      dispatch(updateInfo({ field: "eventRoom", value: location.id }))
    } else {
      if (isConference) {
        dispatch(
          addRemoveLocation({ location, type: isRemoving ? "remove" : "add" })
        )
      } else {
        dispatch(updateInfo({ field: "currentLocation", value: location }))
      }
      dispatch(getRooms({ id: location.id }))
    }
  }

  let title = isConference ? "Conference" : "Event"
  if (isSession) {
    title = "Session"
  }

  const virtualInput = (
    <>
      <h2>Virtual Location{event.type === "Virtual Live" && <>*</>}</h2>
      <div
        className="panel current-selection virtual-location"
        style={{ maxWidth: 500 }}
      >
        <div className="event-select-item flexRow">
          <span className="icon video-conference" />
          <FormControl variant="standard">
            <TextField
              variant="standard"
              label="Meeting Link"
              value={event.meetingLink}
              onBlur={() => {
                validateURL()
              }}
              onFocus={() => {
                setURLWarning(false)
              }}
              helperText={
                "A link to the online meeting for the " +
                title.toLowerCase() +
                ", e.g. Zoom, Meet, etc."
              }
              onChange={(event) =>
                dispatch(
                  updateInfo({
                    field: "meetingLink",
                    value: event.target.value,
                  })
                )
              }
            />
            {URLWarning && (
              <strong className="warning">
                Uh oh, that doesn't look like a valid URL.
              </strong>
            )}
          </FormControl>
        </div>
      </div>
    </>
  )

  let allRooms = []
  conference.locations.forEach((location) => {
    allRooms = allRooms.concat(location.rooms)
  })

  let availableRooms = isSession ? allRooms : event.availableRooms
  if (!availableRooms) {
    availableRooms = []
  }

  let roomId = event.eventRoom?.id ? event.eventRoom.id : event.eventRoom

  let currentRoom =
    availableRooms && availableRooms.length
      ? availableRooms.find((room) => event.eventRoom && room.id === roomId)
      : "none"

  if (!currentRoom) {
    currentRoom = "none"
  }

  const allRoles = props.session.orgRoles
    .concat(props.session.groupRoles)
    .join(",")
  let targetPersonnel =
    props.activeConference &&
    props.activeConference.personnel.find(
      (personnel) => personnel.uuid === props.session.user.id
    )
  let canEdit =
    allRoles.includes("admin") ||
    allRoles.includes("scheduler") ||
    allRoles.includes("observer")

  if (
    !canEdit &&
    targetPersonnel &&
    targetPersonnel.field_event_role_name === "Manager"
  ) {
    canEdit = true
  }

  return (
    <div className="event-content two-column">
      <div
        style={{
          opacity: canEdit ? 1 : 0.5,
          pointerEvents: canEdit ? "all" : "none",
        }}
        className="flexRow columns"
      >
        <div className="column left">
          {event.type !== "Virtual Live" ? (
            <>
              <h2>
                {isConference ? (
                  <>Conference Locations</>
                ) : (
                  <>In-Person Location</>
                )}
              </h2>
              <div
                className={classNames(
                  "required",
                  event.missingFields.includes("Location: Location") && "active"
                )}
              >
                {!isConference && (
                  <EventLocationPanel
                    isSession={isSession}
                    currentRoom={currentRoom}
                    isConference={false}
                    singleLocation={event.currentLocation}
                    changeRoom={(room) => {
                      dispatch(updateInfo({ field: "eventRoom", value: room }))
                    }}
                    availableRooms={availableRooms}
                  />
                )}
              </div>
              {!isSession && (
                <>
                  <header
                    className={classNames(
                      "list-header flexRow",
                      isAdding && "slideUp"
                    )}
                  >
                    <div
                      className="flexRow inputSearch"
                      style={{ marginTop: 15 }}
                    >
                      <span className="icon search" />
                      <TextField
                        variant="standard"
                        label={"Search locations"}
                        value={locations.locationSearch}
                        onChange={(event) =>
                          dispatch(updateLocationSearch(event.target.value))
                        }
                      />
                      <Button
                        onClick={() => {
                          setIsAdding(true)
                        }}
                        className="button small"
                      >
                        <span className="icon add " />
                        Add New
                      </Button>
                    </div>
                  </header>

                  {isAdding && (
                    <div className="add-new panel">
                      <LocationSearchInput {...props} />

                      <FormControl variant="standard">
                        <TextField
                          variant="standard"
                          required
                          label="Location Name"
                          value={
                            props.locations.newLocation.attributes.name
                              ? props.locations.newLocation.attributes.name
                              : ""
                          }
                          onChange={(event) =>
                            dispatch(
                              updateNewLocation({
                                field: "name",
                                value: event.target.value,
                              })
                            )
                          }
                        />
                      </FormControl>

                      <FormControl variant="standard">
                        <TextField
                          variant="standard"
                          multiline
                          label="Additional Instructions"
                          value={
                            props.locations.newLocation.attributes
                              .field_directions
                          }
                          onChange={(event) =>
                            dispatch(
                              updateNewLocation({
                                field: "field_directions",
                                value: event.target.value,
                              })
                            )
                          }
                        />
                      </FormControl>

                      <FormControl variant="standard">
                        <TextField
                          variant="standard"
                          inputProps={{ type: "number", min: 0 }}
                          label="Location Max Capacity"
                          className="max-capacity"
                          value={
                            props.locations.newLocation.attributes
                              .field_capacity
                          }
                          onChange={(event) =>
                            dispatch(
                              updateNewLocation({
                                field: "field_capacity",
                                value: event.target.value,
                              })
                            )
                          }
                        />
                      </FormControl>
                      <footer className="flexRow">
                        <Button
                          onClick={() => {
                            setIsAdding(false)
                          }}
                          className="button small cancel"
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: 25 }}
                          onClick={() => {
                            saveLocation()
                          }}
                          className={classNames(
                            "button small",
                            !props.locations.newLocation.attributes.name &&
                              "disabled"
                          )}
                        >
                          Save
                        </Button>
                      </footer>
                    </div>
                  )}

                  <ul className="event-select-list">
                    {!props.locations.fetched && (
                      <Loading message={"Getting locations..."} />
                    )}

                    {filteredLocations.map((location) => {
                      const address = location.attributes.field_address

                      let isActive
                      if (isSession) {
                        isActive = location.id === event.eventRoom.id
                      } else if (isConference) {
                        isActive = event.locations.find(
                          (loc) => loc.id === location.id
                        )
                      } else {
                        isActive =
                          event.currentLocation &&
                          event.currentLocation.id === location.id
                      }
                      if (!location.attributes.field_archive) {
                        return (
                          <li
                            key={location.id}
                            className={classNames(
                              "event-select-item",
                              isActive && "active",
                              editingLocation &&
                                editingLocation.id === location.id &&
                                "editing"
                            )}
                          >
                            {editingLocation &&
                            editingLocation.id === location.id ? (
                              <>
                                <div
                                  className="flexRow"
                                  style={{
                                    alignItems: "flex-start",
                                    cursor: "pointer",
                                    flex: 1,
                                  }}
                                >
                                  <span
                                    className="icon location color"
                                    style={{ marginTop: 10 }}
                                  />
                                  <div className="details">
                                    <LocationSearchInput
                                      editing={true}
                                      {...props}
                                    />

                                    <FormControl variant="standard">
                                      <TextField
                                        variant="standard"
                                        required
                                        label="Location Name"
                                        value={
                                          editingLocation.attributes.name
                                            ? editingLocation.attributes.name
                                            : ""
                                        }
                                        onChange={(event) =>
                                          dispatch(
                                            updateEditingLocation({
                                              field: "name",
                                              value: event.target.value,
                                            })
                                          )
                                        }
                                      />
                                    </FormControl>

                                    <FormControl
                                      variant="standard"
                                      style={{ marginTop: 15 }}
                                    >
                                      <h2>hi</h2>
                                      <TextField
                                        variant="standard"
                                        multiline
                                        label="Additional Instructions"
                                        value={
                                          editingLocation.attributes
                                            .field_directions
                                            ? editingLocation.attributes
                                                .field_directions
                                            : ""
                                        }
                                        onChange={(event) => {
                                          dispatch(
                                            updateEditingLocation({
                                              field: "field_directions",
                                              value: event.target.value,
                                            })
                                          )
                                        }}
                                      />
                                    </FormControl>

                                    <FormControl variant="standard">
                                      <TextField
                                        variant="standard"
                                        inputProps={{ type: "number", min: 0 }}
                                        label="Location Max Capacity"
                                        className="max-capacity"
                                        value={
                                          editingLocation.attributes
                                            .field_capacity
                                            ? editingLocation.attributes
                                                .field_capacity
                                            : ""
                                        }
                                        onChange={(event) => {
                                          dispatch(
                                            updateEditingLocation({
                                              field: "field_capacity",
                                              value: event.target.value,
                                            })
                                          )
                                        }}
                                      />
                                    </FormControl>

                                    <footer
                                      className="flexRow"
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      <Button
                                        onClick={() => {
                                          dispatch(cancelEdit())
                                        }}
                                        className="button small cancel"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        style={{ marginLeft: 25 }}
                                        onClick={() => {
                                          saveEditingLocation()
                                        }}
                                        className={classNames(
                                          "button small",
                                          !editingLocation.attributes.name &&
                                            "disabled"
                                        )}
                                      >
                                        Save Changes
                                      </Button>
                                    </footer>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="flexRow"
                                  style={{
                                    alignItems: "flex-start",
                                    cursor: "pointer",
                                    flex: 1,
                                  }}
                                  onClick={() => {
                                    if (isConference) {
                                      const isRemoving = Boolean(
                                        event.locations.find(
                                          (loc) => loc.id === location.id
                                        )
                                      )
                                      selectLocation({ location, isRemoving })
                                    } else {
                                      selectLocation({ location })
                                    }
                                  }}
                                >
                                  <span
                                    className="icon location color"
                                    style={{ marginTop: 10 }}
                                  />

                                  <div className="details">
                                    <strong
                                      style={{
                                        display: "block",
                                        marginTop: address ? 0 : 8,
                                      }}
                                    >
                                      {location.attributes.name}
                                    </strong>
                                    <span>
                                      {address && addressToString(address)}
                                    </span>
                                  </div>
                                </div>
                                {!editingLocation && (
                                  <Button
                                    onClick={() => {
                                      editLocation(location)
                                    }}
                                    className="button small"
                                  >
                                    Edit
                                  </Button>
                                )}
                              </>
                            )}
                          </li>
                        )
                      }

                      return null
                    })}
                  </ul>
                </>
              )}
            </>
          ) : (
            <>{virtualInput}</>
          )}
        </div>
        <div className="column right">
          {event.type === "Blended" && <>{virtualInput}</>}
          {event.type !== "Blended" && isConference && (
            <>
              <Box sx={{ marginBottom: 2 }}>
                <LearningBuddyMessage message="You can select multiple locations for this conference. Each location can have multiple rooms, and you'll be able to schedule sessions in any room, in any location." />
              </Box>

              <EventLocationPanel
                isConference={true}
                locations={event.locations}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

class EventLocationContainer extends Component {
  componentDidMount() {
    !this.props.locations.fetched && this.props.getLocations()
    this.props.resetState()
  }

  saveNewLocation = (added) => {
    this.props.saveLocation(this.props.locations.newLocation, true, added)
  }

  render() {
    return (
      <EventLocation
        session={this.props.session}
        rooms={this.props.rooms}
        conference={this.props.conference}
        isConference={this.props.isConference}
        isSession={this.props.isSession}
        updateLocation={this.props.updateLocation}
        setNewLocation={this.props.setNewLocation}
        locations={this.props.locations}
        saveNewLocation={this.saveNewLocation}
        activeEvent={this.props.activeEvent}
        activeConference={this.props.conference}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventLocationContainer)
