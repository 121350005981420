import { Component } from "react"
import { connect } from "react-redux"

import {
  Paper,
  Button,
  FormControl,
  Select,
  TextField,
  Popover,
  MenuItem,
  Menu,
  Dialog,
} from "@mui/material"
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler"

import { Link } from "react-router-dom"

import classNames from "classnames"
import moment from "moment"
import EventTooltip from "./EventTooltip"
import CalendarListView from "./CalendarListView"
import {
  startNewConference,
  setConferenceIsFetched,
  startConferenceFromCalendar,
} from "../conference/activeConferenceSlice"

import {
  debouncedFetchCalendar,
  setSearchActive,
  setShowArchive,
  setSearchQuery,
  setCurrentDate,
  fetchPersonnelEvents,
} from "../../../store/reducers/calendar"

import {
  dropEvent,
  dropExternalEvent,
  unArchiveEvent,
  getLocations,
} from "../../../actions/events"
import plusImg from "../../../img/plus.svg"

import {
  startNewEvent,
  setIsFetched,
  startFromCalendar,
} from "../eventSingle/activeEventSlice"
import "../../../styles/calendar.scss"
import he from "he"
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  AppointmentTooltip,
  DragDropProvider,
  CurrentTimeIndicator,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui"

import MonthCellView from "./MonthCellView"
import Loading from "@mobilemind/common/src/components/Loading"
import ErrorBoundary from "../../../components/ErrorBoundary"
import appointmentColors from "./appointmentColors"
import ButtonSmall from "../../../components/ButtonSmall"
import CurrentTimeLine from "./CurrentTimeLine"
import ObservationIcon from "../../../img/observation.svg"

const mapStateToProps = ({
  calendar,
  feedbackForms,
  locations,
  session,
  externalPD,
}) => {
  const { orgRoles, groupRoles } = session

  const allRoles = orgRoles.concat(groupRoles).join(",")

  const canCreateObservations =
    allRoles.includes("observation") || allRoles.includes("observer")

  const canSchedule =
    allRoles.includes("organization-admin") ||
    allRoles.includes("group-admin") ||
    allRoles.includes("scheduler")

  const isEventPersonnel =
    !canSchedule && orgRoles.includes("organization-event_personnel")

  const observationsOnly = !canSchedule && canCreateObservations

  return {
    feedbackForms,
    externalPD,
    canSchedule,
    canCreateObservations,
    isEventPersonnel,
    observationsOnly,
    locations,
    calendar,
    session,
  }
}

const mapDispatchToProps = {
  debouncedFetchCalendar,
  dropEvent,
  dropExternalEvent,
  startNewEvent,
  startFromCalendar,
  startConferenceFromCalendar,
  setIsFetched,
  setShowArchive,
  fetchPersonnelEvents,
  setSearchActive,
  setCurrentDate,
  getLocations,
  setSearchQuery,
  setConferenceIsFetched,
  startNewConference,
  unArchiveEvent,
}

function Appointment({
  children,
  data,
  onClick,
  classes,
  toggleVisibility,
  onAppointmentMetaChange,
  ...restProps
}) {
  const props = {
    isShaded: restProps.isShaded,
    resources: restProps.resources,
    style: restProps.style,
    type: restProps.type,
  }

  let backgroundColor = appointmentColors.event
  if (data.isExternal) {
    backgroundColor = appointmentColors.external
  }
  if (data.isConference || data.bundle === "conference") {
    backgroundColor = appointmentColors.conference
  }
  return (
    <Appointments.Appointment {...props}>
      <div
        className={classNames(
          "appointmentInner",
          data.bundle === "conference_event" && "session",
          data.field_draft && "draft",
          data.isAllDay && "isAllDay"
        )}
        style={{ backgroundColor }}
        onClick={({ target }) => {
          toggleVisibility()
          onAppointmentMetaChange({
            target: target.parentElement.parentElement,
            data,
          })
        }}
      >
        {!children ? (
          <div style={{ marginLeft: 8, marginTop: 4 }}>
            <header>
              <div>{he.decode(data.title)}</div>
            </header>
          </div>
        ) : (
          <>
            <header style={{ marginTop: 0, marginLeft: 5 }}>
              {he.decode(children[1].props.data.title)}
            </header>
            {data.field_draft && <span className="icon draft" />}
            {!data.field_draft && data.isConference && (
              <span className="icon conference" />
            )}
          </>
        )}
      </div>
    </Appointments.Appointment>
  )
}

class CalendarLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      createAnchorEl: null,
      currentView: "Month",
      visible: false,
      searchQuery: "",
      resize: false,
      createNewVisible: false,
      appointmentMeta: {
        target: null,
        data: {},
      },
    }

    this.toggleVisibility = () => {
      const { visible: tooltipVisibility } = this.state
      this.setState({ visible: !tooltipVisibility })
    }

    this.onAppointmentMetaChange = ({ data, target }) => {
      this.setState({ appointmentMeta: { data, target } })
    }

    this.myAppointment = this.myAppointment.bind(this)
    this.sourceAppointment = this.sourceAppointment.bind(this)
    this.commitChanges = this.commitChanges.bind(this)
  }

  handleResize = () => {
    this.setState({ resize: true })
    this.setState({ resize: false })
  }

  toolTipLayout = (props) => {
    return (
      <Dialog
        id="event-tooltip-container"
        onClose={() => this.setState({ visible: false })}
        open={this.state.visible}
      >
        <EventTooltip appointmentData={props.appointmentMeta.data} />
      </Dialog>
    )
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", this.handleResize)

    const dateRange = {
      min: moment(this.props.calendar.currentDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      max: moment(this.props.calendar.currentDate)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    }

    !this.props.locations.fetched && (await this.props.getLocations())

    this.debouncedFetchCalendar(dateRange)

    this.props.startNewEvent()
    this.props.startNewConference()
    this.props.setSearchQuery("")

    document.addEventListener("keydown", this.handleKeyDown)
  }

  debouncedFetchCalendar = async (dateRange) => {
    const { canSchedule, session, observationsOnly } = this.props
    if (session.orgRoles.includes("organization-event_personnel")) {
      await this.props.fetchPersonnelEvents({ dateRange })
    }
    if (canSchedule || observationsOnly) {
      await this.props.debouncedFetchCalendar({ dateRange, observationsOnly })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown)
  }

  handleShowMoreClick = (day) => {
    this.setState({ showMoreDay: day })
  }

  clearShowMore = () => {
    this.setState({ showMoreDay: null })
  }

  sourceAppointment = (props) => {
    return (
      <div style={{ opacity: 0.5 }}>
        <Appointment
          {...props}
          toggleVisibility={this.toggleVisibility}
          onAppointmentMetaChange={this.onAppointmentMetaChange}
        />
      </div>
    )
  }

  myAppointment(props) {
    return (
      <>
        <Appointment
          {...props}
          toggleVisibility={this.toggleVisibility}
          onAppointmentMetaChange={this.onAppointmentMetaChange}
        />
      </>
    )
  }

  adjustMonth = (direction) => {
    const { currentDate } = this.props.calendar

    let newDate
    if (direction === "back") {
      newDate = moment(currentDate)
        .subtract(1, this.state.currentView.toLowerCase())
        .format("YYYY-MM-DD")
    } else {
      newDate = moment(currentDate)
        .add(1, this.state.currentView.toLowerCase())
        .format("YYYY-MM-DD")
    }
    const dateRange = {
      min: moment(newDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      max: moment(newDate).add(1, "month").endOf("month").format("YYYY-MM-DD"),
    }

    this.props.setCurrentDate(newDate)
    this.debouncedFetchCalendar(dateRange)
  }

  currentViewChange = (event) => {
    this.props.setSearchQuery("")
    this.setState({ searchActive: false, currentView: event.target.value })
  }

  handleKeyDown = (event) => {
    const dateRange = {
      min: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
      max: moment().add(1, "month").endOf("month").format("YYYY-MM-DD"),
    }
    if (
      !this.props.feedbackForms.isOpen &&
      !this.state.isFormVisible &&
      !this.state.searchActive &&
      !this.props.externalPD.currentEvent.isOpen
    ) {
      if (event.keyCode === 68) {
        this.setState({ currentView: "Day" })
      }
      if (event.keyCode === 77) {
        this.setState({ currentView: "Month" })
      }
      if (event.keyCode === 87) {
        this.setState({ currentView: "Week" })
      }
      if (event.keyCode === 83) {
        this.setState({ currentView: "Schedule" })
        this.props.debouncedFetchCalendar({
          dateRange,
          observationsOnly: this.props.observationsOnly,
        })
      } else {
        this.props.setSearchQuery("")
      }
    }
  }

  commitChanges = ({ added, changed, deleted }) => {
    if (this.props.canSchedule && changed) {
      const eventId = Object.keys(changed)[0]
      const externalEvents = this.props.calendar.recommendedEvents.data
      const droppedExternal = externalEvents.find(
        (event) => event.uuid === eventId
      )

      if (droppedExternal) {
        this.props.dropExternalEvent(
          changed,
          this.state.currentView,
          droppedExternal
        )
      } else {
        this.props.dropEvent(changed, this.state.currentView)
      }
    }
  }

  setCreateAnchorEl = (target) => {
    this.setState({ createAnchorEl: target })
  }
  toggleCreateNewMenu = () => {
    this.setState({ createNewVisible: true })
  }

  onCellClick = (event, restProps) => {
    this.setState({
      createNewVisible: moment(restProps.startDate).format(),
    })
    this.setState({ createNewEl: event.target })
  }

  render() {
    const { calendar, canSchedule, history } = this.props

    const allRoles = this.props.session.orgRoles
      .concat(this.props.session.groupRoles)
      .join(",")

    const canObserve =
      allRoles.includes("observation") || allRoles.includes("observer")

    const { currentDate } = calendar
    const {
      currentView,
      visible,
      createAnchorEl,
      appointmentMeta,
      createNewVisible,
    } = this.state

    let headerDateFormat = "MMMM YYYY"
    if (currentView === "Day") {
      headerDateFormat = "MMMM Do YYYY"
    }

    const clickCell = this.onCellClick

    function TimeTableCell({ onDoubleClick, ...restProps }) {
      if (currentView === "Week") {
        return <WeekView.TimeTableCell {...restProps} />
      }
      if (currentView === "Day") {
        return <DayView.TimeTableCell {...restProps} />
      } else {
        let monthProps = { ...restProps }
        monthProps.history = history

        return (
          <MonthCellView
            createNewVisible={createNewVisible}
            onClick={(event) => {
              clickCell(event, restProps)
            }}
            {...monthProps}
          />
        )
      }
    }

    function AllDayCell(props) {
      let eventsOnDay = calendar.data.filter((event) => {
        let isAllDay =
          moment(event.startDate).format("h:mm A") ===
          moment(event.endDate).subtract(24, "hours").format("h:mm A")
        return (
          isAllDay &&
          moment(props.startDate).format("YYYYMMDD") ===
            moment(event.startDate).format("YYYYMMDD")
        )
      })

      const height = eventsOnDay.length ? 30 * eventsOnDay.length : "auto"
      return <AllDayPanel.Cell style={{ height }} {...props} />
    }

    let startingData =
      currentView === "Month" ? calendar.truncatedData : calendar.data

    const finalData = startingData
      ? startingData.filter((event) => {
          if (calendar.searchQuery) {
            return (
              event.title &&
              event.title
                .toLowerCase()
                .includes(calendar.searchQuery.toLowerCase())
            )
          }
          return !event.field_archive && event
        })
      : []

    const { canCreateObservations } = this.props

    return (
      <ErrorBoundary>
        <div className="page calendarLayout eventsLayout">
          <div className="pageInner">
            <header className="toolbar">
              <div className="calendar-legend flexRow">
                <div className="item">
                  <div
                    className="circle"
                    style={{ backgroundColor: appointmentColors.event }}
                  />
                  <strong>Event</strong>
                </div>
                <div className="item">
                  <div
                    className="circle"
                    style={{ backgroundColor: appointmentColors.conference }}
                  />
                  <strong>Conference</strong>
                </div>
                <div className="item">
                  <div
                    className="circle"
                    style={{ backgroundColor: appointmentColors.external }}
                  />
                  <strong>External Event</strong>
                </div>
                {!this.props.calendar.fetched && (
                  <Loading message="Refreshing your calendar..." />
                )}
              </div>

              <div className="flexRow">
                <Button
                  className={classNames(
                    "button small",
                    currentView === "Schedule" && "hidden"
                  )}
                  onClick={() => {
                    this.props.setCurrentDate(moment().format("YYYY-MM-DD"))

                    const dateRange = {
                      min: moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                      max: moment()
                        .add(1, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD"),
                    }

                    this.debouncedFetchCalendar(dateRange)
                  }}
                >
                  Today
                </Button>

                <div
                  className={classNames(
                    "adjustRange",
                    currentView === "Schedule" && "hidden"
                  )}
                >
                  <div
                    className="caret active"
                    onClick={() => {
                      this.adjustMonth("back")
                    }}
                  />
                  <div
                    className="caret flipped active"
                    onClick={() => {
                      this.adjustMonth("forward")
                    }}
                  />
                  {currentView !== "Week" ? (
                    <span>{moment(currentDate).format(headerDateFormat)}</span>
                  ) : (
                    <span>
                      {moment(currentDate).startOf("week").format("MMMM Do")} -{" "}
                      {moment(currentDate).endOf("week").format("MMMM Do")}{" "}
                      {moment(currentDate).format("YYYY")}{" "}
                    </span>
                  )}
                </div>

                <div className="viewControl">
                  <div className="searchWrapper">
                    <div className="flexRow inputSearch">
                      <TextField
                        variant="standard"
                        label="Search Events"
                        value={calendar.searchQuery}
                        onFocus={() => this.setState({ searchActive: true })}
                        onBlur={() => this.setState({ searchActive: false })}
                        onChange={(event) => {
                          this.props.setSearchQuery(event.target.value)
                          this.setState({ currentView: "Schedule" })
                        }}
                      />
                    </div>
                  </div>

                  <FormControl
                    variant="standard"
                    className="inputSelect viewSelect"
                  >
                    <Select
                      variant="standard"
                      labelId="view-select"
                      id="view-select"
                      value={currentView}
                      onChange={this.currentViewChange}
                    >
                      <MenuItem value={"Day"}>
                        <span className="label">Day</span>
                        <strong className="key">D</strong>
                      </MenuItem>
                      <MenuItem value={"Week"}>
                        <span className="label">Week</span>
                        <strong className="key">W</strong>
                      </MenuItem>
                      <MenuItem value={"Month"}>
                        <span className="label">Month</span>
                        <strong className="key">M</strong>
                      </MenuItem>
                      <MenuItem value={"Schedule"}>
                        <span className="label">Schedule</span>
                        <strong className="key">S</strong>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {(canSchedule || canCreateObservations) && (
                  <Button
                    style={{ marginLeft: 15 }}
                    onClick={(event) => {
                      this.setCreateAnchorEl(event.currentTarget)
                    }}
                    className="button small"
                    aria-owns={createAnchorEl ? "create-menu" : undefined}
                  >
                    <img alt={"Add New"} src={plusImg} /> Add New
                  </Button>
                )}

                <Menu
                  id="create-menu"
                  className="create-menu"
                  anchorEl={createAnchorEl}
                  open={Boolean(createAnchorEl)}
                  onClose={() => this.setCreateAnchorEl(null)}
                >
                  {canSchedule && (
                    <>
                      <MenuItem>
                        <Link to="/events/event/new">
                          <span className="icon calendar" />
                          Single Event
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/events/conference/new">
                          <span className="icon conference" />
                          Conference
                        </Link>
                      </MenuItem>
                    </>
                  )}

                  {canCreateObservations && (
                    <MenuItem>
                      <Link to="/events/observation/new">
                        <img
                          src={ObservationIcon}
                          alt="Observation"
                          width={20}
                          style={{ marginRight: 10 }}
                        />
                        Observation
                      </Link>
                    </MenuItem>
                  )}
                </Menu>
              </div>
            </header>

            <Popover
              className="createNewPopover"
              open={
                this.state.createNewEl && moment(createNewVisible).isValid()
                  ? true
                  : false
              }
              onClose={() => {
                this.setState({ createNewEl: false })
                setTimeout(() => {
                  this.setState({ createNewVisible: false })
                }, 500)
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "top",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "top",
              }}
              anchorEl={this.state.createNewEl}
            >
              <h2>{moment(createNewVisible).format("MMMM Do YYYY")}</h2>
              <ul>
                {canSchedule && (
                  <>
                    <li>
                      <Link
                        onClick={() => {
                          this.props.startNewEvent()
                          this.props.setIsFetched()
                          setTimeout(() => {
                            this.props.startFromCalendar({
                              startDate: createNewVisible,
                            })
                          }, 100)
                        }}
                        to="/events/event/new"
                      >
                        <ButtonSmall>
                          <span className="icon event" />
                          New Event
                        </ButtonSmall>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          this.props.startNewConference()
                          this.props.setConferenceIsFetched()
                          setTimeout(() => {
                            this.props.startConferenceFromCalendar({
                              startDate: createNewVisible,
                            })
                          }, 100)
                        }}
                        to="/events/conference/new"
                      >
                        <ButtonSmall>
                          <span className="icon conference" />
                          New Conference
                        </ButtonSmall>
                      </Link>
                    </li>
                  </>
                )}

                {canObserve && (
                  <li>
                    <Link
                      onClick={() => {
                        this.props.startNewEvent()
                        this.props.setIsFetched()
                        setTimeout(() => {
                          this.props.startFromCalendar({
                            startDate: createNewVisible,
                          })
                        }, 100)
                      }}
                      to="/events/observation/new"
                    >
                      <ButtonSmall>
                        <span className="icon observation blue" />
                        New Observation
                      </ButtonSmall>
                    </Link>
                  </li>
                )}
              </ul>
            </Popover>

            <div className={classNames("calendarInner main", currentView)}>
              <Paper>
                <Scheduler data={finalData}>
                  {currentView === "Schedule" && (
                    <CalendarListView {...this.props} />
                  )}

                  <ViewState
                    currentDate={currentDate}
                    currentViewName={currentView}
                  />

                  <EditingState onCommitChanges={this.commitChanges} />

                  <IntegratedEditing />

                  <MonthView timeTableCellComponent={TimeTableCell} />

                  <WeekView
                    timeTableCellComponent={TimeTableCell}
                    cellDuration={60}
                    startDayHour={0}
                  />

                  <DayView
                    timeTableCellComponent={TimeTableCell}
                    cellDuration={60}
                    startDayHour={0}
                  />

                  <Appointments appointmentComponent={this.myAppointment} />
                  {visible && (
                    <AppointmentTooltip
                      visible={visible}
                      id={"test-ID"}
                      layoutComponent={this.toolTipLayout}
                      contentComponent={EventTooltip}
                      onVisibilityChange={this.toggleVisibility}
                      appointmentMeta={appointmentMeta}
                      onAppointmentMetaChange={this.onAppointmentMetaChange}
                    />
                  )}
                  <AllDayPanel cellComponent={AllDayCell} />
                  {canSchedule && (
                    <DragDropProvider
                      draftAppointmentComponent={this.myAppointment}
                      sourceAppointmentComponent={this.sourceAppointment}
                    />
                  )}

                  <CurrentTimeIndicator indicatorComponent={CurrentTimeLine} />
                </Scheduler>
              </Paper>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarLayout)
