import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

export const fetchActiveGoal = createAsyncThunk(
  "activeGoalSlice/fetchActiveGoal",
  async (goalId, thunkAPI) => {
    let query = {
      filter: {
        drupal_internal__id: goalId,
      },
      include:
        "field_subgroup,field_job_title,field_teacher,field_learning_path,field_organization",
    }

    let response = await fetchWrapper.get(
      "/api/goal_entity/learning_path_goal?" + qs.stringify(query)
    )
    if (response.ok) {
      let data = await response.json()
      data.orgId = thunkAPI.getState().session.group.uuid[0].value
      data.isSiteAdmin = thunkAPI.getState().session.isSiteAdmin

      let goal = data.data[0]

      let subGroups =
        data.included &&
        data.included.filter((included) => included.type === "group--group")
      let subGroupIds =
        goal.relationships.field_subgroup.data &&
        goal.relationships.field_subgroup.data.map((group) => group.id)

      if (!subGroups || !subGroups.length) {
        let groupLevelSubGroups = thunkAPI.getState().session.subgroups
        data.subGroups =
          groupLevelSubGroups &&
          groupLevelSubGroups.data &&
          groupLevelSubGroups.data.filter((group) =>
            subGroupIds.includes(group.id)
          )
      } else {
        data.subGroups = subGroups
      }

      data.jobTitles =
        data.included &&
        data.included.filter(
          (included) => included.type === "taxonomy_term--job_titles"
        )
      data.users =
        data.included &&
        data.included.filter((included) => included.type === "user--user")

      let learningPath =
        data.included &&
        data.included.find(
          (included) => included.type === "learning_path--learning_path"
        )

      if (learningPath) {
        query = {
          lp: learningPath.attributes.drupal_internal__id,
        }
        response = await fetchWrapper.get(
          "/api/learning_path/explore?" + qs.stringify(query)
        )
        if (response.ok) {
          let pathData = await response.json()

          data.learningPath = pathData.lp_data[0]
        }
      }

      return data
    }
  }
)

export const getLearningPaths = createAsyncThunk(
  "complianceSlice/getLearningPaths",
  async (args, thunkAPI) => {
    const { activeGoal } = thunkAPI.getState()

    let response = await fetchWrapper.get(
      "/api/learning_path/explore?" +
        qs.stringify({ search: activeGoal.LPSearch })
    )

    if (response.ok) {
      let data = await response.json()
      return data.lp_data
    }
  }
)

const debouncedGetLearningPaths = debounceThunk(getLearningPaths, 750)
export const updateLPSearch = createAsyncThunk(
  "complianceSlice/updateLPSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetLearningPaths())
    return args
  }
)

const today = moment()
const deadline = moment().add(1, "month").format()
const expiration = moment().add(6, "months").format()

export const activeGoalSlice = createSlice({
  name: "activeGoalSlice",
  initialState: {
    unauthorized: false,
    fetched: false,
    name: "",
    draft: true,
    subGroups: [],
    jobTitles: [],
    estimatedTime: 0,
    users: [],
    dailyPace: 0,
    weeklyPace: 0,
    deadline: deadline,
    expirationDate: expiration,
    prevGoal: null,
    isEmailSend: false,
    missingFields: [],
    subGroupOwner: null,
    goalReminders: ["none"],
    drupal_internal__id: null,
    LPSearch: "",
    LPSearchResults: [],
    learningPath: null,
  },
  reducers: {
    startNewGoal: (state) => {
      state.name = ""
      state.id = null
      state.unauthorized = false
      state.fetched = true
      state.subGroups = []
      state.jobTitles = []
      state.field_draft = true
      state.estimatedTime = 0
      state.dailyPace = 0
      state.weeklyPace = 0
      state.deadline = deadline
      state.expirationDate = expiration
      state.prevGoal = null
      state.isEmailSend = false
      state.missingFields = []
      state.subGroupOwner = null
      state.goalReminders = ["none"]
      state.drupal_internal__id = null

      state.LPSearch = ""
      state.LPSearchResults = []
      state.learningPath = null
    },
    setEmailSend: (state, action) => {
      state.isEmailSend = action.payload
    },
    updateField: (state, action) => {
      // Prevent duplicates due to AutoComplete weirdness
      if (
        action.payload.field === "jobTitles" ||
        action.payload.field === "users"
      ) {
        state[action.payload.field] = action.payload.value.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        )
      } else if (action.payload.field === "subGroups") {
        state[action.payload.field] = action.payload.value.filter(
          (v, i, a) =>
            a.findIndex((t) => t.attributes.label === v.attributes.label) === i
        )
      } else if (action.payload.field === "goalReminders") {
        if (
          action.payload.value.includes("none") &&
          !state.goalReminders.includes("none")
        ) {
          state.goalReminders = ["none"]
        } else if (state.goalReminders === ["none"]) {
          state.goalReminders = action.payload.value
        } else {
          state.goalReminders = action.payload.value.filter(
            (item) => item !== "none"
          )
        }
      } else {
        if (
          action.payload.field === "deadline" ||
          action.payload.field === "expirationDate"
        ) {
          state[action.payload.field] = moment(
            action.payload.value.split("T")[0]
          ).format()
        } else {
          state[action.payload.field] = action.payload.value
        }
      }
    },
    setLearningPath: (state, action) => {
      state.learningPath = action.payload
    },
  },
  extraReducers: {
    [getLearningPaths.fulfilled]: (state, action) => {
      state.LPSearchResults = action.payload
    },

    [updateLPSearch.pending]: (state, action) => {
      state.LPSearch = action.meta.arg
    },

    [fetchActiveGoal.pending]: (state) => {
      state.fetched = false
      state.drupal_internal__id = null
    },
    [fetchActiveGoal.fulfilled]: (state, action) => {
      const orgId = action.payload.orgId
      const goal = action.payload.data[0]

      if (
        action.payload.isSiteAdmin ||
        (goal &&
          goal.relationships.field_organization.data &&
          orgId === goal.relationships.field_organization.data.id)
      ) {
        state.unauthorized = false
        state.name = goal.attributes.name
        state.id = goal.id
        state.field_draft = goal.attributes.field_draft
        state.isEmailSend = goal.attributes.field_email_send ? true : false
        state.subGroupOwner =
          goal.relationships.field_subgroup_owner.data &&
          goal.relationships.field_subgroup_owner.data.id
        state.drupal_internal__id = goal.attributes.drupal_internal__id
        state.goalReminders = goal.attributes.field_goal_reminder.length
          ? goal.attributes.field_goal_reminder
          : ["none"]

        let expirationDate = goal.attributes.field_expiration_date
          ? moment(goal.attributes.field_expiration_date).format()
          : moment().format()
        state.expirationDate = expirationDate

        let deadlineDate = moment(goal.attributes.field_goal_date).format()
        state.deadline = deadlineDate

        if (action.payload.learningPath) {
          state.learningPath =
            action.payload.learningPath && action.payload.learningPath

          const timeToComplete = action.payload.learningPath.totalEstimatedTime
          state.estimatedTime = timeToComplete

          const daysLeft = moment(state.deadline).diff(today, "days")
          const weeksLeft = moment(state.deadline).diff(today, "weeks")

          state.dailyPace = Math.ceil(timeToComplete / daysLeft)
          state.weeklyPace = weeksLeft
            ? Math.ceil(timeToComplete / weeksLeft)
            : timeToComplete
        }

        state.subGroups = action.payload.subGroups
          ? action.payload.subGroups
          : []
        state.jobTitles = action.payload.jobTitles
          ? action.payload.jobTitles
          : []
        state.users = action.payload.users ? action.payload.users : []

        state.prevGoal = goal
        state.prevGoal.subGroups = state.subGroups
        state.prevGoal.jobTitles = state.jobTitles

        state.fetched = true
      } else {
        state.unauthorized = true
      }
    },
  },
})

export const { startNewGoal, updateField, setLearningPath, setEmailSend } =
  activeGoalSlice.actions

export default activeGoalSlice.reducer
