import he from "he"

import classNames from "classnames"
import moment from "moment"

import { Appointments } from "@devexpress/dx-react-scheduler-material-ui"

import ErrorBoundary from "../../../components/ErrorBoundary"

export function Appointment({
  children,
  data,
  onClick,
  classes,
  toggleVisibility,
  onAppointmentMetaChange,
  color,
  textColor,
  ...restProps
}) {
  const props = {
    isShaded: restProps.isShaded,
    resources: restProps.resources,
    style: restProps.style,
    type: restProps.type,
  }

  return (
    <ErrorBoundary>
      <Appointments.Appointment style={{ fontSize: 499 }} {...props}>
        <div
          className={classNames(
            "appointmentInner conference-event",
            data.field_archive && "isArchived",
            data.roomId === "none" && "virtual",
            data.field_draft && "draft"
          )}
          style={{ backgroundColor: color }}
          onClick={({ target }) => {
            toggleVisibility()
            onAppointmentMetaChange({
              target: target.parentElement.parentElement,
              data,
            })
          }}
        >
          {!children ? (
            <div style={{ marginLeft: 8, marginTop: 2 }}>
              <header>
                {data.title && (
                  <div style={{ color: textColor }}>
                    {he.decode(data.title)}
                  </div>
                )}
              </header>
            </div>
          ) : (
            <>
              <header style={{ padding: "0 5px", color: textColor }}>
                <div
                  style={{
                    whiteSpace: "pre",
                    paddingTop: 5,
                    marginBottom: -5,
                  }}
                >
                  {data.title && <>{he.decode(data.title)}</>}
                </div>
                <div>
                  {moment(data.startDate).format("h:mmA")} -{" "}
                  {moment(data.endDate).format("h:mmA")}
                </div>
              </header>

              {data.field_archive && <span className="icon archive" />}
              {data.field_draft && <span className="icon draft" />}
            </>
          )}
        </div>
      </Appointments.Appointment>
    </ErrorBoundary>
  )
}
