import { Component } from "react"
import { connect } from "react-redux"
import { saveGoal, deleteGoal } from "../../actions/goals"
import NavbarSecondary from "../../components/NavbarSecondary"
import ModalDelete from "../../components/ModalDelete"
import { getCategories } from "../../store/reducers/categories"
import { updateField } from "./activeGoalSlice"
import { isGroupLevel } from "../../functions"
import moment from "moment"

import "../../styles/navbar.scss"

const mapStateToProps = ({ activeGoal, categories, session }) => {
  return {
    activeGoal,
    categories,
    session,
  }
}

const mapDispatchToProps = {
  saveGoal,
  getCategories,
  updateField,
  deleteGoal,
}

class GoalNavbarContainer extends Component {
  state = {
    isDeleteOpen: false,
    isPreviewOpen: false,
  }

  componentDidMount = () => {
    !this.props.categories.fetched && this.props.getCategories()
  }

  saveDraft = () => {
    this.props.saveGoal(this.props.activeGoal, true).then(() => {
      this.props.history.push("/create/goals")
    })
  }

  deleteGoal = () => {
    if (this.props.activeGoal.field_draft) {
      this.props.deleteGoal(this.props.activeGoal.id).then(() => {
        this.props.history.push("/create/goals")
      })
    } else {
      this.props.history.push("/create/goals")
      let windowParams =
        "left=300,top=350,width=525,height=300,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
      const confirmWindow =
        process.env.REACT_APP_API_URL +
        "/user_goal/batch-delete/" +
        this.props.activeGoal.drupal_internal__id
      window.open(confirmWindow, "", windowParams)
    }
  }

  publish = async () => {
    const { activeGoal } = this.props
    this.props
      .saveGoal(this.props.activeGoal, false, !this.props.activeGoal.id)
      .then((response) => {
        this.props.history.push("/create/goals")

        let changedFields
        if (activeGoal.id) {
          if (
            JSON.stringify(activeGoal.subGroups) !==
              JSON.stringify(activeGoal.prevGoal.subGroups) ||
            JSON.stringify(activeGoal.jobTitles) !==
              JSON.stringify(activeGoal.prevGoal.jobTitles)
          ) {
            changedFields = true
          }
        }

        if (!activeGoal.id || activeGoal.field_draft || changedFields) {
          let windowParams =
            "left=300,top=350,width=525,height=300,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
          const confirmWindow =
            process.env.REACT_APP_API_URL +
            "/user_goal/batch-create/" +
            response.attributes.drupal_internal__id
          window.open(confirmWindow, "", windowParams)
        }
      })
  }

  highlightFields = (missingFields) => {
    this.props.updateField({ field: "missingFields", value: missingFields })
  }

  render() {
    const { activeGoal, session } = this.props

    let missingFields = []
    let missingDraftFields = []
    !activeGoal.name && missingFields.push("Goal Title")
    !activeGoal.name && missingDraftFields.push("Goal Title")
    !activeGoal.learningPath && missingFields.push("Learning Path to Complete")
    !activeGoal.learningPath &&
      missingDraftFields.push("Learning Path to Complete")

    const deadline = activeGoal.deadline.split("T")[0]
    const expirationDate = activeGoal.expirationDate.split("T")[0]

    if (moment(deadline).isAfter(moment(expirationDate))) {
      missingFields.push("Expiration date must be on or after goal deadline")
    }

    if (
      isGroupLevel(session) &&
      session.subgroups.data &&
      session.subgroups.data.length > 0
    ) {
      if (!activeGoal.subGroups.length && !session.isGroupAdmin) {
        missingFields.push("Make visible to at least 1 group")
      }
    }

    let canSave = activeGoal.name
    let canPublish =
      activeGoal.name &&
      (!isGroupLevel(session) ||
        activeGoal.subGroups.length ||
        session.subgroups.data.length < 2) &&
      !missingFields.length

    const props = {
      type: "Learner Goal",
      iconClass: "icon goal white",
      path: "/create/goals",
      publish: this.publish,
      saveDraft: this.saveDraft,
      onHover: this.highlightFields,
      canDelete:
        session.orgRoles.includes("organization-admin") || session.isSiteAdmin,
      openDeleteModal: () => {
        this.setState({ isDeleteOpen: true })
      },
      openPreview: (type) => {
        this.setState({ isPreviewOpen: true, previewType: type })
      },
      activeItem: activeGoal,
      canSave,
      canPublish,
      missingFields,
      missingDraftFields,
      groupRoles: session.groupRoles,
      orgRoles: session.orgRoles,
    }

    return (
      <>
        <ModalDelete
          type={"goal"}
          open={this.state.isDeleteOpen}
          path={"/create/goals"}
          deleteItem={this.deleteGoal}
          onClose={() => {
            this.setState({ isDeleteOpen: false })
          }}
        />
        <NavbarSecondary {...props} />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalNavbarContainer)
