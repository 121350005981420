import moment from "moment"
import { Component } from "react"
import { connect } from "react-redux"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import Snackbar from "./components/Snackbar"

import ProfileLayout from "./components/ProfileLayout"
import DashboardLayout from "./features/dashboard/DashboardLayout"
import ServiceLayout from "./features/serviceAccount/ServiceAccountLayout"

import Navbar from "./features/navbar/Navbar"

import CreateGoalLayout from "./features/createGoal/CreateGoalLayout"
import GoalListContainer from "./features/entityList/GoalListContainer"

import CreateBadgeLayout from "./features/createBadge/CreateBadgeLayout"
import BadgeListContainer from "./features/entityList/BadgeListContainer"

import CreateAnnouncementLayout from "./features/createAnnouncement/CreateAnnouncementLayout"
import AnnouncementsListContainer from "./features/entityList/AnnouncementsListContainer"

import ManageCategoriesModal from "./features/categories/ManageCategoriesModal"
import { CourseFormContainer } from "./features/courseForm/CourseFormContainer"
import CourseListContainer from "./features/entityList/CourseListContainer"

import CreateLearningPathLayout from "./features/createLearningPath/CreateLearningPathLayout"
import LearningPathListContainer from "./features/entityList/LearningPathListContainer"

import EventSingleLayout from "./features/events/eventSingle/EventSingleLayout"
import ManageLocationsLayout from "./features/locations/ManageLocationsLayout"

import ConferenceLiveViewLayout from "./features/events/conference/ConferenceLiveViewLayout"
import LiveViewLayout from "./features/events/eventSingle/LiveViewLayout"

import ComplianceLayout from "./features/compliance/ComplianceLayout"
import ConferenceContainer from "./features/events/conference/ConferenceContainer"
import LeaderboardsLayout from "./features/leaderboards/LeaderboardsLayout"
import TeacherAccessLayout from "./features/teacherAccess/TeacherAccessLayout"

import Unauthorized from "@mobilemind/common/src/components/Unauthorized"
import CreateQuickLinksForm from "./features/createQuickLinks/CreateQuickLinksForm"

import BackpackContainer from "./features/backpack/BackpackContainer"
import BackpackPrintLayout from "./features/backpack/BackpackPrintLayout"

import OrganizationSingle from "./features/organizations/OrganizationSingle.js"
import OrganizationList from "./features/organizations/OrganizationsList.js"
import ReviewContainer from "./features/review/ReviewContainer"

import BadgeStudio from "./features/createBadge/BadgeStudio"
import { fetchHQAnnouncements } from "./features/hqAnnouncements/hqAnnouncementsSlice"
import { getCategories } from "./store/reducers/categories"
import { EditRubricContainer } from "./features/rubrics/EditRubricContainer"

import EventsContainer from "./features/events/EventsContainer"

import "./styles/badgeItem.scss"
import "./styles/badgeStudio.scss"
import "./styles/calendar.scss"
import "./styles/categories.scss"
import "./styles/certificate.scss"
import "./styles/courseCard.scss"
import "./styles/courseSelect.scss"
import "./styles/create.scss"
import "./styles/event-live-view.scss"
import "./styles/event-single.scss"
import "./styles/external-pd.scss"
import "./styles/index.scss"
import "./styles/modal.scss"
import "./styles/reports.scss"
import "./styles/review.scss"
import "./styles/sidebar.scss"
import "./styles/widget.scss"

import RubricLayout from "./features/rubrics/RubricLayout"

import classNames from "classnames"
import pluralize from "pluralize"
import { getOrgBadges } from "./actions/badges"
import { getOrgCourses } from "./actions/courses"
import { getOrgLearningPaths } from "./actions/learningPaths"
import SubmitTicket from "./components/SubmitTicket"
import CreateMandatedTrainingLayout from "./features/createMandatedTraining/CreateMandatedTrainingLayout"
import MandatedTrainingListContainer from "./features/entityList/MandatedTrainingListContainer"
import ReviewerGroupsModal from "./features/review/ReviewerGroupsModal"
import { getOrgSuccessChecklist, getOrgUsers } from "./store/reducers/session"

import { CollectionSettingsLayout } from "features/collectionSettings/CollectionSettingsLayout"
import CollectionDashboardLayout from "features/dashboard/CollectionDashboardLayout"
import FeedbackFormsModal from "features/feedbackForms/FeedbackFormsModal"
import { PartnerMarketplaceLayout } from "features/marketplace/PartnerMarketplaceLayout"
import { OrgSettingsLayout } from "features/orgSettings/OrgSettingsLayout"
import { PartnerSettingsLayout } from "features/partnerSettings/PartnerSettingsLayout"
import { CourseTemplateContainer } from "features/courseForm/CourseTemplateContainer"
import ErrorBoundary from "components/ErrorBoundary"
import { RubricListContainer } from "features/entityList/RubricListContainer"
import { AnalyticsLayout } from "features/analytics/AnalyticsLayout"

const mapStateToProps = ({
  session,
  activeCourse,
  activeGoal,
  activeBadge,
  activeLearningPath,
  activeMandatedTraining,
  activeAnnouncement,
}) => {
  return {
    session,
    groupRoles: session.groupRoles,
    orgRoles: session.orgRoles,
    activeGoal,
    activeCourse,
    activeBadge,
    activeLearningPath,
    activeMandatedTraining,
    activeAnnouncement,
  }
}

const mapDispatchToProps = {
  fetchHQAnnouncements,
  getCategories,
  getOrgLearningPaths,
  getOrgUsers,
  getOrgSuccessChecklist,
  getOrgBadges,
  getOrgCourses,
}

class App extends Component {
  state = {
    subscriptionWarningDismissed: false,
  }

  componentDidMount() {
    this.props.fetchHQAnnouncements()
    this.props.getCategories()
    this.props.getOrgSuccessChecklist()

    if (this.props.session.isPartner) {
      this.props.getOrgLearningPaths()
      this.props.getOrgBadges({})
      this.props.getOrgCourses({})
    }
  }

  render() {
    const {
      session,
      activeCourse,
      activeBadge,
      activeGoal,
      activeLearningPath,
      activeMandatedTraining,
      activeAnnouncement,
    } = this.props

    const isHQ =
      session.isSiteAdmin ||
      (session.group &&
        session.group.field_hq[0] &&
        session.group.field_hq[0].value)

    const {
      isCollectionAdmin,
      canViewApp,
      isOrgAdmin,
      isGroupAdmin,
      isServiceAdmin,
      canCreate,
      canSchedule,
      canReport,
      canCreateGoals,
      canReview,
      isEventPersonnel,
      canViewBackpack,
      reviewerTypes,
      subscriptionEnd,
      customerSupportEmail,
      isPartner,
    } = session

    const canReviewExternal =
      canReview && reviewerTypes.includes("external_event")
    const isObserver =
      session.orgRoles.includes("organization-observer") ||
      session.orgRoles.includes("organization-observation_admin") ||
      session.groupRoles.includes("group-observer") ||
      session.groupRoles.includes("group-observation_admin")

    const canViewMandatedTraining = isOrgAdmin || isGroupAdmin || canCreate
    const canViewAnalytics =
      isOrgAdmin ||
      session.groupRoles.includes("group-observation_admin") ||
      session.orgRoles.includes("organization-observation_admin")

    const hideEvents = session.group?.field_hide_events?.[0]?.value

    const subscriptionEndsIn =
      moment(subscriptionEnd).diff(moment(), "days") + 1

    const DashboardComponent =
      session.isCollectionAdmin && !session.activeMembership?.data
        ? CollectionDashboardLayout
        : DashboardLayout

    return (
      <BrowserRouter>
        {canViewApp ? (
          <>
            {!window.location.href.includes("print") && (
              <Navbar {...this.props} isAuthorized={true} />
            )}

            <ManageCategoriesModal />
            <ReviewerGroupsModal />
            <FeedbackFormsModal />

            {subscriptionEndsIn >= 1 &&
              subscriptionEndsIn <= 90 &&
              isOrgAdmin &&
              !this.state.subscriptionWarningDismissed &&
              !window.location.href.includes("/print") && (
                <div className="subscription-warning">
                  <div
                    className={classNames(
                      "inner",
                      subscriptionEndsIn <= 30 && "danger"
                    )}
                  >
                    <div
                      className="dismissWrapper"
                      onClick={() =>
                        this.setState({ subscriptionWarningDismissed: true })
                      }
                    >
                      <div className="dismiss">Dismiss</div>
                    </div>
                    <div
                      className="flexRow"
                      style={{ justifyContent: "center", marginBottom: 10 }}
                    >
                      <span className="icon renew" />
                      <strong>
                        Your subscription ends
                        {subscriptionEndsIn === 1 ? (
                          <> tomorrow!</>
                        ) : (
                          <>
                            {" "}
                            in {subscriptionEndsIn}{" "}
                            {pluralize("day", subscriptionEndsIn)}!
                          </>
                        )}
                      </strong>
                    </div>
                    <p>
                      Contact{" "}
                      <a
                        href={`mailto:${
                          customerSupportEmail
                            ? customerSupportEmail
                            : "info@mobilemind.io"
                        }`}
                      >
                        {customerSupportEmail
                          ? customerSupportEmail
                          : "info@mobilemind.io"}
                      </a>{" "}
                      to avoid losing access.
                    </p>
                  </div>
                </div>
              )}

            <ErrorBoundary>
              <Switch>
                <Route
                  exact
                  path="/"
                  component={canViewApp ? DashboardComponent : Unauthorized}
                />
                <Route exact path="/profile" component={ProfileLayout} />

                <Route
                  exact
                  path="/service-account"
                  component={isServiceAdmin ? ServiceLayout : Unauthorized}
                />
                <Route
                  exact
                  path="/org-settings"
                  component={isOrgAdmin ? OrgSettingsLayout : Unauthorized}
                />

                <Route
                  exact
                  path="/marketplace"
                  component={PartnerMarketplaceLayout}
                />
                <Route
                  exact
                  path="/partner-settings"
                  component={isPartner ? PartnerSettingsLayout : Unauthorized}
                />
                <Route
                  exact
                  path="/collection-settings"
                  component={
                    isCollectionAdmin ? CollectionSettingsLayout : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/create/rubrics"
                  component={
                    isHQ && isOrgAdmin ? RubricListContainer : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/create/rubrics/new"
                  component={
                    isHQ && canCreate ? EditRubricContainer : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/create/rubrics/:uuid"
                  component={
                    isHQ && canCreate ? EditRubricContainer : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/create/courses"
                  component={
                    isHQ && canCreate ? CourseListContainer : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/create/courses/:id/template"
                  component={
                    isHQ && canCreate ? CourseTemplateContainer : Unauthorized
                  }
                />
                <Route
                  exact
                  path={["/create/courses/new", "/create/courses/:id/edit"]}
                  component={
                    isHQ && canCreate && !activeCourse.unauthorized
                      ? CourseFormContainer
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/goals"
                  component={canCreateGoals ? GoalListContainer : Unauthorized}
                />

                <Route
                  exact
                  path="/create/mandated-training"
                  component={
                    canViewMandatedTraining
                      ? MandatedTrainingListContainer
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/mandated-training/:id/:type?"
                  component={
                    canViewMandatedTraining &&
                    !activeMandatedTraining.unauthorized
                      ? CreateMandatedTrainingLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/goals/:id/:type?"
                  component={
                    canCreateGoals && !activeGoal.unauthorized
                      ? CreateGoalLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/badges"
                  component={
                    isHQ && canCreate ? BadgeListContainer : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/badges/:id/:type?"
                  component={
                    isHQ && canCreate && !activeBadge.unauthorized
                      ? CreateBadgeLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/learning-paths"
                  component={
                    isHQ && canCreate ? LearningPathListContainer : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/create/learning-paths/:id/:type?"
                  component={
                    isHQ && canCreate && !activeLearningPath.unauthorized
                      ? CreateLearningPathLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/announcements"
                  component={
                    canCreate || isCollectionAdmin
                      ? AnnouncementsListContainer
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/announcements/:id/:type?"
                  component={
                    (canCreate || isCollectionAdmin) &&
                    !activeAnnouncement.unauthorized
                      ? CreateAnnouncementLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/create/quick-links"
                  component={isOrgAdmin ? CreateQuickLinksForm : Unauthorized}
                />

                <Route
                  exact
                  path="/review"
                  component={canReview ? ReviewContainer : Unauthorized}
                />
                <Route
                  exact
                  path="/organizations"
                  component={
                    session.isSiteAdmin ? OrganizationList : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/organizations/:id"
                  component={
                    session.isSiteAdmin ? OrganizationSingle : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/events"
                  component={
                    (canSchedule ||
                      isEventPersonnel ||
                      canReviewExternal ||
                      isObserver) &&
                    !hideEvents
                      ? EventsContainer
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/events/view/event/:id/"
                  component={
                    canSchedule || isEventPersonnel
                      ? LiveViewLayout
                      : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/events/view/session/:id/:returnTab?"
                  component={
                    canSchedule || isEventPersonnel
                      ? LiveViewLayout
                      : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/events/view/conference/:id"
                  component={
                    canSchedule || isEventPersonnel
                      ? ConferenceLiveViewLayout
                      : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/events/view/observation/:id"
                  component={
                    canSchedule || isEventPersonnel || isObserver
                      ? LiveViewLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/events/event/:id"
                  component={
                    canSchedule || isEventPersonnel
                      ? EventSingleLayout
                      : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/events/session/:id"
                  component={
                    canSchedule || isEventPersonnel
                      ? EventSingleLayout
                      : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/events/conference/:id"
                  component={
                    canSchedule || isEventPersonnel
                      ? ConferenceContainer
                      : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/events/observation/:id"
                  component={
                    canSchedule || isEventPersonnel || isObserver
                      ? EventSingleLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/events/locations"
                  component={
                    canSchedule || isObserver
                      ? ManageLocationsLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/reports/leaderboards"
                  component={canReport ? LeaderboardsLayout : Unauthorized}
                />
                <Route
                  exact
                  path="/reports/compliance"
                  component={canReport ? ComplianceLayout : Unauthorized}
                />
                <Route
                  exact
                  path="/reports/learner-access"
                  component={canReport ? TeacherAccessLayout : Unauthorized}
                />
                <Route
                  exact
                  path="/reports/analytics"
                  component={canViewAnalytics ? AnalyticsLayout : Unauthorized}
                />

                <Route
                  exact
                  path="/backpack/:id"
                  component={
                    canViewBackpack || isObserver
                      ? BackpackContainer
                      : Unauthorized
                  }
                />
                <Route
                  exact
                  path="/backpack/:id/print"
                  component={
                    canViewBackpack || isObserver
                      ? BackpackPrintLayout
                      : Unauthorized
                  }
                />

                <Route
                  exact
                  path="/rubric/:rubricId/:userId"
                  component={
                    isOrgAdmin || isObserver ? RubricLayout : Unauthorized
                  }
                />

                <Route exact path="/badge-studio" component={BadgeStudio} />

                <Route exact path="/submit-ticket" component={SubmitTicket} />
              </Switch>
            </ErrorBoundary>

            <Snackbar />
          </>
        ) : (
          <>
            <Navbar isAuthorized={false} />
            <Unauthorized />
          </>
        )}
      </BrowserRouter>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
