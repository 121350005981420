import { Button, Dialog, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"

import { getReviewerGroups } from "./reviewSlice"

import "../../styles/categories.scss"
import "../../styles/modal.scss"
import {
  getReviewerGroupMembers,
  setCurrentReviewerGroup,
  setReviewerGroupsModalOpen,
} from "./reviewSlice"

import {
  createReviewerGroup,
  createReviewerGroupMembership,
  deleteReviewerGroupMembership,
  updateReviewerGroupName,
} from "../../actions"
import { getOrgUsers } from "store/reducers/session"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import Loading from "@mobilemind/common/src/components/Loading"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import classNames from "classnames"
import he from "he"
import ButtonSmall from "../../components/ButtonSmall"
import UserSelect from "../userSelect/UserSelect"

function ReviewerGroupsModal() {
  const assessments = useAppSelector((state) => state.assessments)
  const session = useAppSelector((state) => state.session)
  const { reviewerGroups, currentReviewerGroup } = assessments
  const { orgUsers } = session
  const [isUserSelectOpen, setUserSelectOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [newGroupName, setNewGroupName] = useState("")
  const [search, setSearch] = useState("")

  const [newGroupActive, setNewGroupActive] = useState(false)
  const [usersFetched, setUsersFetched] = useState(false)
  const [isRenaming, setIsRenaming] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!reviewerGroups.fetched) {
      dispatch(getReviewerGroups())
    }
    if (!usersFetched) {
      setUsersFetched(true)
      dispatch(getOrgUsers())
    }
  }, [reviewerGroups.fetched, dispatch, usersFetched])

  async function addMember(event: any) {
    setIsLoading(true)
    await dispatch(
      createReviewerGroupMembership({ user: event.user, currentReviewerGroup })
    )
    setIsLoading(false)
  }

  return (
    <>
      <UserSelect
        open={isUserSelectOpen}
        entity={currentReviewerGroup}
        currentReviewerGroup={currentReviewerGroup}
        initialTab="reviewers"
        update={addMember}
        onClose={() => setUserSelectOpen(false)}
        visibleTabs={"users"}
        userLabel={"reviewers"}
        searchText={
          "Search for reviewers to add them to " +
          currentReviewerGroup.name +
          "."
        }
      />
      <Dialog
        data-no-focus-lock
        onClose={() => {
          dispatch(setReviewerGroupsModalOpen(false))
        }}
        className={classNames("modal reviewer-groups-modal")}
        open={assessments.isReviewerGroupsModalOpen}
      >
        {!currentReviewerGroup.id ? (
          <>
            <h2>
              <div style={{ flex: 1 }}>Reviewer Groups</div>

              {!newGroupActive && !currentReviewerGroup.id && (
                <ButtonSmall
                  onClick={() => {
                    setNewGroupName("")
                    setNewGroupActive(true)
                  }}
                >
                  <div className="icon add" />
                  New Reviewer Group
                </ButtonSmall>
              )}

              {isLoading && <Loading />}
            </h2>

            <TextField
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              placeholder={"Search Reviewer Groups..."}
            />
          </>
        ) : (
          <h2>
            <ButtonSmall
              onClick={() => {
                setIsRenaming(false)
                dispatch(setCurrentReviewerGroup(null))
              }}
            >
              <span className="icon caret" />
            </ButtonSmall>

            <span className="icon reviewer-group" />

            {!isRenaming && (
              <>
                <div style={{ flex: 1 }}>{currentReviewerGroup.name}</div>
                <ButtonSmall
                  onClick={() => {
                    setNewGroupName(currentReviewerGroup.name)
                    setIsRenaming(true)
                  }}
                >
                  <span className="icon edit" />
                  Rename Group
                </ButtonSmall>
                <ButtonSmall onClick={() => setUserSelectOpen(true)}>
                  <span className="icon add" />
                  Add Member
                </ButtonSmall>
              </>
            )}
            {isRenaming && (
              <>
                <div style={{ flex: 1, marginRight: 25 }}>
                  <TextField
                    value={newGroupName}
                    onChange={(event) => setNewGroupName(event.target.value)}
                    style={{ width: "100%" }}
                    placeholder={"Reviewer Group Name"}
                  />
                </div>
                {!isLoading && (
                  <>
                    <ButtonSmall
                      onClick={() => {
                        setNewGroupName("")
                        setIsRenaming(false)
                      }}
                    >
                      Cancel
                    </ButtonSmall>
                    <div style={{ marginLeft: 25 }}>
                      <ButtonSmall
                        onClick={async () => {
                          setIsLoading(true)
                          await dispatch(
                            updateReviewerGroupName({
                              groupName: newGroupName,
                              entity_id: currentReviewerGroup.id,
                            })
                          )
                          setIsLoading(false)
                          setIsRenaming(false)
                        }}
                      >
                        Save
                      </ButtonSmall>
                    </div>
                  </>
                )}
              </>
            )}

            {isLoading && (
              <div style={{ marginLeft: 25 }}>
                <Loading />
              </div>
            )}
          </h2>
        )}

        {newGroupActive && (
          <div className="flexRow newGroupContainer">
            <TextField
              value={newGroupName}
              onChange={(event) => setNewGroupName(event.target.value)}
              style={{ width: "55%" }}
              placeholder={"New Reviewer Group Name"}
            />
            <ButtonLarge
              onClick={() => setNewGroupActive(false)}
              style={{ backgroundColor: "#f0f0f0" }}
            >
              Cancel
            </ButtonLarge>
            <ButtonLarge
              onClick={() => {
                setIsLoading(true)
                setNewGroupActive(false)
                dispatch(createReviewerGroup(newGroupName)).then(() => {
                  setIsLoading(false)
                  dispatch(getReviewerGroups())
                })
              }}
            >
              Save
            </ButtonLarge>
          </div>
        )}
        {!currentReviewerGroup.id ? (
          <ul className="groups-list">
            {reviewerGroups.data.map((group: any, index: number) => {
              const shouldReturn =
                !search.length ||
                group.attributes.label
                  .toLowerCase()
                  .includes(search.toLowerCase())

              if (shouldReturn) {
                return (
                  <li key={index}>
                    <Button
                      onClick={() => {
                        setNewGroupActive(false)
                        dispatch(
                          setCurrentReviewerGroup({
                            id: group.relationships.entity_id
                              ? group.relationships.entity_id.data.id
                              : group.id,
                            name: group.attributes.label,
                          })
                        )
                        dispatch(getReviewerGroupMembers())
                      }}
                    >
                      <span className="icon reviewer-group" />
                      <strong className="group-title">
                        {group.attributes.label}
                      </strong>
                    </Button>
                  </li>
                )
              }
              return null
            })}
          </ul>
        ) : (
          <>
            {currentReviewerGroup.fetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <Loading />
              </div>
            ) : (
              <ul className="group-members-list">
                {currentReviewerGroup.members.map(
                  (member: any, index: number) => {
                    const orgUser = orgUsers.data.find((user: any) => {
                      return (
                        Number(user.uid) ===
                        member.relationships.entity_id.data.meta
                          .drupal_internal__target_id
                      )
                    })

                    if (orgUser) {
                      return (
                        <li key={index} className="user-item">
                          <div style={{ flex: 1 }}>
                            <UserLabel
                              firstName={he.decode(orgUser.field_first_name)}
                              lastName={he.decode(orgUser.field_last_name)}
                              imagePath={
                                process.env.REACT_APP_API_URL +
                                orgUser.user_picture
                              }
                              subgroups={orgUser.field_subgroup}
                            />
                          </div>

                          <ButtonSmall
                            onClick={() => {
                              setIsLoading(true)
                              dispatch(
                                deleteReviewerGroupMembership(member.id)
                              ).then(() => {
                                setIsLoading(false)
                              })
                            }}
                          >
                            Remove
                          </ButtonSmall>
                        </li>
                      )
                    }
                    return null
                  }
                )}
              </ul>
            )}
          </>
        )}
      </Dialog>
    </>
  )
}

export default ReviewerGroupsModal
