import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import _ from "lodash"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

let extractColumn = (arr, column) => arr.map((x) => x[column])

export const getReviewerGroups = createAsyncThunk(
  "reviewSlice/getReviewerGroups",
  async (args, thunkAPI) => {
    let response = await fetchWrapper.get(
      "/api/group_content/organization-subgroup-rev_group"
    )

    if (response.ok) {
      let data = await response.json()
      return data.data
    }
  }
)

export const getReviewerGroupMembers = createAsyncThunk(
  "reviewSlice/getReviewerGroupMembers",
  async (args, thunkAPI) => {
    const { assessments } = thunkAPI.getState()

    let response = await fetchWrapper.get(
      "/api/group_content/rev_group-group_membership?filter%5Bgid.id%5D%5Bvalue%5D=" +
        assessments.currentReviewerGroup.id
    )

    if (response.ok) {
      let data = await response.json()
      return data.data
    }
  }
)

export const getAssessments = createAsyncThunk(
  "reviewSlice/getAssessments",
  async (args, thunkAPI) => {
    const { searchQuery, filters, reviewType, currentTab } = args
    const { learningPath, category, group, sortBy, sortOrder } = filters

    const session = thunkAPI.getState().session
    const roles = extractColumn(session.roles, "target_id")

    const orgRoles = session.orgRoles
    const groupRoles = session.groupRoles

    const subgroups =
      session.subgroups &&
      extractColumn(session.subgroups.data, "drupal_internal__id")

    const org = session.group && session.group.id[0].value
    let groupData

    // org/group
    let baseUrl = "/api/reviewer"

    // site roles
    if (roles.includes("administrator") || roles.includes("reviewer")) {
      baseUrl += "/mm"
    }

    if (roles.includes("tier_1_grader")) {
      baseUrl += "/mm/tier-1"
    }

    // control base url based on group/org admin status when reviewType is admin
    if (reviewType === "admin") {
      if (orgRoles.includes("organization-admin")) {
        baseUrl = "/api/reviewer/organization/" + org
      } else if (groupRoles.includes("group-admin")) {
        baseUrl = "/api/reviewer/group/" + subgroups.join("+")
      }
    }
    // reviewed is the same for all
    if (currentTab === "reviewed") {
      baseUrl = "/api/reviewed"
    }

    let query = {
      lp_id: learningPath ? learningPath.attributes.drupal_internal__id : "",
      combine: searchQuery,
      gid: group === "All" ? "" : group,
      page: 0,
    }
    if (currentTab !== "reviewed") {
      query.sort_by = sortBy
      query.sort_order = sortOrder
    }
    if (category) {
      query.field_category = category.attributes.drupal_internal__tid
    }

    let assessments = {
      items: [],
      total: 0,
      per_page: 0,
    }

    let response = await fetchWrapper.get(baseUrl + "?" + qs.stringify(query))
    if (response.ok) {
      let data = await response.json()

      assessments.total_items = Number(data.pager.total_items)
      assessments.items_per_page = Number(data.pager.items_per_page)
      if (!data.rows.content) {
        assessments.items = data.rows
      }
      // get total from last page
      if (data.pager.total_pages - 1 > 0) {
        let last = { page: 0 }
        last.page = data.pager.total_pages - 1
        let last_page = await fetchWrapper.get(
          baseUrl + "?" + qs.stringify(last)
        )
        if (last_page.ok) {
          let counts = await last_page.json()
          assessments.total_items = Number(counts.pager.total_items)
          assessments.items_per_page = Number(counts.pager.items_per_page)
        }
      }
    }

    if (currentTab === "reviewed") {
      let totalRequest = await fetchWrapper.get(
        "/api/reviewed/count?" + qs.stringify(query)
      )
      let totalsData = await totalRequest.json()

      assessments.total_items = Number(totalsData.count)
    }

    if (reviewType !== "admin") {
      let groupResponse = await fetchWrapper.get(
        "/api/rev_group?" + qs.stringify(query)
      )

      if (groupResponse.ok) {
        groupData = await groupResponse.json()

        if (!groupData.rows.content) {
          groupData.rows.forEach((row) => {
            assessments.items.push(row)
          })
        }
        assessments.total_items += Number(groupData.pager.total_items)
      }
    }

    return assessments
  }
)

export const getAssignedCourses = createAsyncThunk(
  "reviewSlice/getAssignedCourses",
  async (args, thunkAPI) => {
    let response

    const { isOrgAdmin } = thunkAPI.getState().session
    const { search } = thunkAPI.getState().assessments.assignedCourses.filters

    const query = {
      search,
    }

    if (isOrgAdmin) {
      response = await fetchWrapper.get(
        "/api/admin-review?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        let sorted = _.orderBy(data.data, ["assessment_count"], ["desc"])
        return sorted
      }
    } else {
      response = await fetchWrapper.get(
        "/api/reviewer-assigned-courses?" + qs.stringify(query)
      )
      let data = await response.json()
      let rows = []
      if (!data.rows.content) {
        rows = data.rows
      }
      return rows
    }
  }
)

const debouncedGetAssignedCourse = debounceThunk(getAssignedCourses, 750)
export const updateAssignedCourseSearch = createAsyncThunk(
  "reviewSlice/updateAssignedCourseSearch",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetAssignedCourse())
    return args
  }
)

export const setSortBy = createAsyncThunk(
  "reviewSlice/setSortBy",
  async (args, thunkAPI) => {
    const { assessments } = thunkAPI.getState()
    const { currentTab, reviewType, filters, searchQuery } = assessments

    thunkAPI.dispatch(
      getAssessments({
        currentTab,
        reviewType,
        filters,
        searchQuery,
      })
    )

    return args
  }
)

export const setSortOrder = createAsyncThunk(
  "reviewSlice/setSortOrder",
  async (args, thunkAPI) => {
    const { assessments } = thunkAPI.getState()
    const { currentTab, reviewType, filters, searchQuery } = assessments

    thunkAPI.dispatch(
      getAssessments({
        currentTab,
        reviewType,
        filters,
        searchQuery,
      })
    )

    return args
  }
)

export const getAssessmentRevisions = createAsyncThunk(
  "reviewSlice/getAssessmentRevisions",
  async (args, thunkAPI) => {
    const { id, uuid } = args

    // Set assessment to grading_in_progress
    const { session } = thunkAPI.getState()

    let body = JSON.stringify({
      data: {
        id: uuid,
        type: "assessment_entity--assessment_entity",
        attributes: {
          field_status: "grading_in_progress",
          field_last_updated_by: [
            {
              value: session.user.attributes.uid,
            },
          ],
        },
        relationships: {
          field_reviewer: {
            data: {
              type: "user--user",
              id: session.user.id,
            },
          },
        },
      },
    })
    await fetchWrapper.patch(
      "/api/assessment_entity/assessment_entity/" + uuid,
      session.token,
      body
    )

    let response = await fetchWrapper.get(
      "/api/assessment_entity/revisions/" + id
    )
    if (response.ok) {
      const data = await response.json()
      return data
    }
  }
)

export const revertAssessmentGradingInProgress = createAsyncThunk(
  "reviewSlice/revertAssessmentGradingInProgress",
  async (args, thunkAPI) => {
    const { uuid } = args
    // Set assessment to grading_in_progress
    const { session } = thunkAPI.getState()

    let body = JSON.stringify({
      data: {
        id: uuid,
        type: "assessment_entity--assessment_entity",
        attributes: {
          field_status: "submitted",
          field_last_updated_by: [
            {
              value: session.user.attributes.uid,
            },
          ],
        },
        relationships: {
          field_reviewer: {
            data: null,
          },
        },
      },
    })
    await fetchWrapper.patch(
      "/api/assessment_entity/assessment_entity/" + uuid,
      session.token,
      body
    )
  }
)

export const reviewSlice = createSlice({
  name: "reviewSlice",
  initialState: {
    data: [],
    fetched: false,
    currentTab: "review",
    reviewType: "reviewer",
    isViewByCourseModalOpen: false,
    learningPath: {
      missingEntity: true,
    },
    searchQuery: "",
    currentRevisions: [],
    activeRevision: null,
    filters: {
      learningPath: null,
      searchInput: "",
      category: null,
      sortBy: "changed",
      group: "All",
      sortOrder: "DESC",
    },
    assignedCourses: {
      data: [],
      fetched: false,
      filters: {
        search: "",
      },
    },
    isReviewerGroupsModalOpen: false,
    reviewerGroups: {
      data: [],
      fetched: false,
    },
    currentReviewerGroup: {
      name: "",
      id: null,
      members: [],
      fetching: false,
    },
  },
  reducers: {
    setIsViewByCourseOpen: (state, action) => {
      state.isViewByCourseModalOpen = action.payload
    },
    createReviewerGroup: (state, action) => {
      state.reviewerGroups.data.push(action.payload)
      state.reviewerGroups.data = _.orderBy(state.reviewerGroups.data, [
        "attributes.label",
      ])
    },
    addReviewerGroupMembership: (state, action) => {
      state.currentReviewerGroup.members =
        state.currentReviewerGroup.members.concat([action.payload])
    },
    setReviewerGroupsModalOpen: (state, action) => {
      state.isReviewerGroupsModalOpen = action.payload
    },
    setCurrentReviewerGroup: (state, action) => {
      if (action.payload) {
        const { id, name } = action.payload
        state.currentReviewerGroup.id = id
        state.currentReviewerGroup.name = name
      } else {
        state.currentReviewerGroup.id = null
        state.currentReviewerGroup.name = ""
      }
    },
    updateReviewerGroupName: (state, action) => {
      let targetGroup = state.reviewerGroups.data.find((group) => {
        if (
          group.relationships.entity_id &&
          group.relationships.entity_id.data
        ) {
          return group.relationships.entity_id.data.id === action.meta.id
        } else {
          return group.id === action.meta.id
        }
      })

      state.currentReviewerGroup.name = action.payload
      targetGroup.attributes.label = action.payload
    },
    setLearningPath: (state, action) => {
      state.filters.learningPath = action.payload
      state.learningPath.missingEntity = false
    },
    removeCourse: (state, action) => {
      const { course } = action.payload
      state.assignedCourses.data = state.assignedCourses.data.filter(
        (existing) => existing.id !== course.id
      )
    },
    removeAssessment: (state, action) => {
      state.data = state.data.filter(
        (existing) =>
          existing.drupal_internal__id !== action.payload.drupal_internal__id
      )
    },
    setSearchInput: (state, action) => {
      state.filters.searchInput = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
      state.data = []
    },
    setCategory: (state, action) => {
      state.filters.category = action.payload
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload
    },
    setReviewType: (state, action) => {
      state.reviewType = action.payload
    },
    setGroup: (state, action) => {
      state.filters.group = action.payload
    },
    clearRevisions: (state) => {
      state.currentRevisions = []
      state.activeRevision = null
    },
    setActiveRevision: (state, action) => {
      state.activeRevision = state.currentRevisions.find(
        (rev) => rev.vid === action.payload
      )
    },
    deleteReviewerGroupMembership: (state, action) => {
      state.currentReviewerGroup.members =
        state.currentReviewerGroup.members.filter(
          (user) => user.id !== action.payload
        )
    },
    resetFilters: (state) => {
      let { searchInput, learningPath, category, group } = state.filters
      if (
        searchInput !== "" ||
        learningPath !== null ||
        category ||
        group !== "" ||
        state.reviewType !== "reviewer"
      ) {
        state.searchQuery = ""
        state.reviewType = "reviewer"
        state.filters = {
          learningPath: null,
          searchInput: "",
          category: null,
          group: "All",
        }
      }
    },
  },
  extraReducers: {
    [getAssessments.pending]: (state) => {
      state.data = []
      state.fetched = false
    },
    [getReviewerGroups.fulfilled]: (state, action) => {
      state.reviewerGroups.fetched = true
      state.reviewerGroups.data = _.orderBy(action.payload, [
        "attributes.label",
      ])
    },
    [getAssessments.fulfilled]: (state, action) => {
      state.data = action.payload.items
      state.items_per_page = action.payload.items_per_page
      state.total_items = Number(action.payload.total_items)
      state.fetched = true
    },
    [updateAssignedCourseSearch.pending]: (state, action) => {
      state.assignedCourses.filters.search = action.meta.arg
    },
    [getReviewerGroupMembers.pending]: (state) => {
      state.currentReviewerGroup.fetching = true
      state.currentReviewerGroup.members = []
    },
    [getReviewerGroupMembers.fulfilled]: (state, action) => {
      state.currentReviewerGroup.fetching = false
      state.currentReviewerGroup.members = action.payload
    },
    [getAssessmentRevisions.fulfilled]: (state, action) => {
      state.currentRevisions = !action.payload.rows.content
        ? action.payload.rows.reverse().slice(1)
        : []

      state.activeRevision =
        state.currentRevisions[state.currentRevisions.length - 1]
    },
    [setSortBy.pending]: (state, action) => {
      state.filters.sortBy = action.meta.arg
    },
    [setSortOrder.pending]: (state, action) => {
      state.filters.sortOrder = action.meta.arg
    },
    [getAssignedCourses.fulfilled]: (state, action) => {
      state.assignedCourses.data = action.payload
      state.assignedCourses.fetched = true
    },
  },
})

export const {
  setLearningPath,
  setPathType,
  setSearchQuery,
  removeAssessment,
  clearRevisions,
  setActiveRevision,
  setSearchInput,
  setCategory,
  setCurrentTab,
  setReviewType,
  resetFilters,
  setGroup,
  setReviewerGroupsModalOpen,
  setCurrentReviewerGroup,
  setIsViewByCourseOpen,
} = reviewSlice.actions

export default reviewSlice.reducer
